/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { updateBank, getBank } from "../../functions/bank";
import AdminNav from "../../components/nav/AdminNav";
import BankForm from "../../components/forms/BankForm";

const BankUpdate = ({ history, match }) => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [bankId, setBankId] = useState("");
  const [swift, setSwift] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadCategory = () =>
      getBank(match.params.id).then((c) => {
        setName(c.data.name);
        setCode(c.data.bankId);
        setSwift(c.data.bankSwift);
        setStatus(c.data.status);
      });
    loadCategory();
  }, [match.params.id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    updateBank(
      match.params.id,
      { name: name, bankId: code, bankSwift: swift, status: status },
      authtoken
    )
      .then((res) => {
        setLoading(false);
        setName("");
        toast.success(
          `Category with name "${res.data.data.name}" updated successfully`
        );
        history.push("/banks");
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
      });
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>Update Bank</h4>
      )}

      <BankForm
        handleSubmit={handleSubmit}
        name={name}
        setName={setName}
        code={code}
        setCode={setCode}
        bankId={bankId}
        status={status}
        setStatus={setStatus}
        setBankId={setBankId}
        swift={swift}
        setSwift={setSwift}
      />
    </div>
  );
};

export default BankUpdate;
