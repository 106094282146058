/* eslint-disable no-undef */
import axios from "axios";

export const createPartner = async (partner, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/createPartner`,
    partner,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getPartnersByCount = async (count) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/listPartner/${count}`
  );
};
export const fetchPartnersByFilter = async (arg) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/search/filters`,
    arg
  );
};

export const getPartner = async (slug) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/partner/${slug}`);
};
export const getRelated = async (partnerId) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/partner/related/${partnerId}`
  );
};
export const removePartner = async (id, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/partner/delete/${id}`,

    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updatePartner = async (slug, partner, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/partner/${slug}`,
    partner,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getPartners = async (sort, order, page, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/allPartner`,
    {
      sort,
      order,
      page,
    },
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getPartnerTotal = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/partnerCount`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const partnerStar = async (partnerId, star, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/partner/star/${partnerId}`,
    { star },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const searchPartnerByName = async (name, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/partner/search/${name}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
