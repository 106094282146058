import React, { useState } from "react";
import { Layout, Menu } from "antd";
// import {
//   HomeOutlined,
//   SettingOutlined,
//   UserOutlined,
//   UserAddOutlined,
//   LogoutOutlined,
//   ShopOutlined,
// } from "@ant-design/icons";
import { Link } from "react-router-dom";
// import firebase from "firebase";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// import Search from "./Search";
import logo from "../../assets/images/logo-admin.png";

const { Header } = Layout;
const { Item } = Menu;

const Heads = () => {
  const [current, setCurrent] = useState("home");
  let dispatch = useDispatch();
  // let { user } = useSelector((state) => ({ ...state }));
  let history = useHistory();

  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const logout = () => {
    // firebase.auth().signOut();
    localStorage.removeItem("token");
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    history.push("/login");
  };
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1,
        width: "100%",
        backgroundColor: "#fff",
      }}
    >
      <Header
        style={{ backgroundColor: "#fff", marginTop: -10, marginBottom: 10 }}
      >
        <Menu
          onClick={handleClick}
          selectedKeys={[current]}
          mode="horizontal"
          theme="light"
          className="nav-bar-menu"
        >
          <Item key="home">
            <img src={logo} width={128} height={53} />
          </Item>
          {/* <Item key="home">
            <Link to="/">Service Hub</Link>
          </Item> */}
          <Item
            key="logout"
            style={{ right: 0, poisition: "absolute", float: "right" }}
          >
            <Link to="#" onClick={() => logout()}>
              Logout
            </Link>
          </Item>
        </Menu>
      </Header>
    </div>
  );
};

export default Heads;
