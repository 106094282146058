/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { getTechnician, updateTechnician } from "../../functions/technician";
import AdminNav from "../../components/nav/AdminNav";
import AdminForm from "../../components/forms/AdminForm";
// import FileUpload from "../../components/forms/FileUpload";

const TechnicianUpdate = ({ history, match }) => {
  //router
  let { id } = match.params;
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state) => ({ ...state }));
  const { fullName, email, mainPhone, profilePicture, status } = values;
  const authtoken = localStorage.getItem("token");
  console.log("IMAGE API", process.env.REACT_IMAGE_API);
  useEffect(() => {
    loadAdmin();
  }, []);

  const loadAdmin = () => {
    getTechnician(id, authtoken)
      .then((res) => {
        //console.log("SINGLE PRODUCT", p);
        setValues(res.data.technician);
      })
      .catch((error) => console.log(error));
  };
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateTechnician(id, values, authtoken)
      .then((res) => {
        console.log("UPDATE", res);
        window.alert(`Admin ${res.data.data.fullName} updated successfully`);
        setLoading(false);
        history.push("/technicians");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  return (
    <div className="container-fluid">
      <h4>Edit Technician</h4>
      <hr />

      {/* {JSON.stringify(values)} */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            className="form-control col-6"
            value={fullName}
            onChange={handleChange}
            placeholder="Username"
            disabled
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control col-6"
            value={email}
            onChange={handleChange}
            placeholder="Email"
            disabled
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="phone"
            name="phone"
            className="form-control col-6"
            value={mainPhone}
            onChange={handleChange}
            placeholder="Phone"
            disabled
          />
        </div>
        <div className="form-group">
          <label>Profile Picture</label>
          <image
            src={`${process.env.REACT_IMAGE_API}/${profilePicture}`}
            width={200}
            height={200}
          />
        </div>
        <div className="form-group">
          <label>Status</label>
          <select
            name="status"
            className="form-control col-4"
            onChange={handleChange}
            value={status}
          >
            <option>- Pilih Status-</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
            <option value="reject">Reject</option>
            <option value="blocked">Blocked</option>
          </select>
        </div>

        <br />
        <button className="btn btn-outline-primary" disabled={loading}>
          Save
        </button>
      </form>
    </div>
  );
};

export default TechnicianUpdate;
