/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { updateSubCategory, getSubCategory } from "../../functions/subcategory";
import { getCategories } from "../../functions/category";
import AdminNav from "../../components/nav/AdminNav";
import CategoryForm from "../../components/forms/CategoryForm";

const SubCategoryUpdate = ({ history, match }) => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [parent, setParent] = useState("");

  useEffect(() => {
    loadCategories();
    const loadSubCategory = () =>
      getSubCategory(match.params.id).then((s) => {
        setName(s.data.name);
        setParent(s.data.parent);
      });
    loadSubCategory();
  }, [match.params.id]);
  const loadCategories = () =>
    getCategories().then((c) => setCategories(c.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("NAME", name);
    setLoading(true);
    updateSubCategory(match.params.id, { name, parent }, authtoken)
      .then((res) => {
        setLoading(false);
        // setName("");
        toast.success(
          `SubCategory with name "${res.data.data.name}" updated successfully`
        );
        history.push("/subcategories");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.message);
      });
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>Update SubCategory</h4>
      )}
      <div className="form-group">
        <label>Category</label>
        <select
          name="category"
          className="form-control col-3"
          onChange={(e) => setParent(e.target.value)}
        >
          <option>Pick Parent Category</option>
          {categories.length > 0 &&
            categories.map((c) => (
              <option value={c._id} key={c._id} selected={c._id === parent}>
                {c.name}
              </option>
            ))}
        </select>
      </div>

      <CategoryForm handleSubmit={handleSubmit} name={name} setName={setName} />
      <p>{JSON.stringify(name)}</p>
    </div>
  );
};

export default SubCategoryUpdate;
