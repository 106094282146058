/* eslint-disable react/prop-types */
import React from "react";

const CityForm = ({ handleSubmit, handleChange, values }) => {
  return (
    <form onSubmit={handleSubmit}>
      {JSON.stringify(values)}
      <div className="row">
        <div className="col-4">
          <label>Name</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            name="name"
            className="form-control"
            value={values.name}
            onChange={handleChange}
            placeholder="City Name"
            autoFocus
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>City Code</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            name="code"
            className="form-control"
            value={values.code}
            onChange={handleChange}
            placeholder="City Code"
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <label>Status</label>
        </div>
        <div className="col-8">
          <select
            className="form-control col-8"
            onChange={handleChange}
            name="status"
          >
            <option value="active" selected={values.status === "active"}>
              Active
            </option>
            <option value="inactive" selected={values.status === "inactive"}>
              Inactive
            </option>
          </select>
        </div>
      </div>
      <br />
      <button className="btn btn-outline-primary">Save</button>
    </form>
  );
};

export default CityForm;
