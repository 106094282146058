/* eslint-disable react/prop-types */
import React from "react";

const BankForm = ({
  handleSubmit,
  name,
  setName,
  code,
  setCode,
  bankId,
  setBankId,
  swift,
  setSwift,
  status,
  setStatus,
}) => {
  console.log(bankId, setBankId);
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4">
          <label>Name</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Bank Name"
            autoFocus
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Bank Code</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Bank Code"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Swift Code</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={swift}
            onChange={(e) => setSwift(e.target.value)}
            placeholder="Bank Swift"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Status</label>
        </div>
        <div className="col-8">
          <select
            className="form-control col-8"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active" selected={status === "active"}>
              Active
            </option>
            <option value="inactive" selected={status === "inactive"}>
              Inactive
            </option>
          </select>
        </div>
      </div>
      <br />
      <button className="btn btn-outline-primary">Save</button>
    </form>
  );
};

export default BankForm;
