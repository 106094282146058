import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";

import { createBank, getBanks, removeBank } from "../../functions/bank";
import AdminNav from "../../components/nav/AdminNav";
import BankForm from "../../components/forms/BankForm";
import LocalSearch from "../../components/forms/LocalSearch";

const ListBank = () => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [bankId, setBankId] = useState("");
  const [swift, setSwift] = useState("");
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [status, setStatus] = useState("");

  // searching & filtering
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    loadBanks();
  }, []);
  const loadBanks = () => getBanks().then((c) => setBanks(c.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    const status = "active";
    const bankData = { name, code, swift, status };
    createBank(bankData, authtoken)
      .then((res) => {
        setLoading(false);
        setName("");
        setCode("");
        setSwift("");
        toast.success(
          `Bank with name "${res.data.data.name}" created successfully`
        );
        setIsModalVisible(false);
        loadBanks();
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
      });
  };
  const handleRemove = async (slug) => {
    if (window.confirm("Are You Sure?")) {
      setLoading(true);
      removeBank(slug, authtoken)
        .then((res) => {
          setLoading(false);
          toast.success(`Success Delete "${res.data.name}" Bank`);
          loadBanks();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };
  // handle change

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>List Bank</h4>
      )}

      <Button type="primary" onClick={showModal}>
        <PlusOutlined /> Create Bank
      </Button>
      <Modal
        title="Create Bank"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <BankForm
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
          code={code}
          setCode={setCode}
          bankId={bankId}
          setBankId={setBankId}
          swift={swift}
          status={status}
          setStatus={setStatus}
          setSwift={setSwift}
        />
      </Modal>
      <hr />
      <LocalSearch setKeyword={setKeyword} keyword={keyword} />
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Swift Code</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {banks.filter(searched(keyword)).map((c) => (
            <tr key={c._id}>
              <td> {c.name}</td>
              <td>{c.bankId}</td>
              <td>{c.bankSwift}</td>
              <td>
                {c.status === "active" ? (
                  <span
                    style={{
                      backgroundColor: "green",
                      padding: 5,
                      color: "#fff",
                      borderRadius: 5,
                    }}
                  >
                    Active
                  </span>
                ) : (
                  <span
                    style={{
                      backgroundColor: "gray",
                      padding: 5,
                      color: "#fff",
                      borderRadius: 5,
                    }}
                  >
                    Inactive
                  </span>
                )}
              </td>
              <td>
                <Link
                  to={`/bank/edit/${c._id}`}
                  className="btn btn-primary btn-xs"
                >
                  <EditOutlined /> Edit
                </Link>
                |
                <span
                  onClick={() => handleRemove(c.slug)}
                  className="btn btn-danger btn-xs"
                >
                  <DeleteOutlined /> Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListBank;
