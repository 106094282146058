/* eslint-disable react/prop-types */
import React from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import Moment from "moment";
import "moment/locale/id";
import { Link } from "react-router-dom";

// import defaultImage from "../../images/default.png";

const ListTransaction = ({ order, handleRemove, no }) => {
  const {
    _id,
    status,
    type,
    order_id,
    customer,
    technician,
    total_price,
    payment,
  } = order;
  Moment.locale("id");

  return (
    <tr>
      <td>
        <input type="checkbox" name="" id="" />
      </td>
      <td>{no + 1}</td>
      <td>{order_id}</td>
      <td>{type}</td>
      <td>{customer}</td>
      <td>{technician}</td>
      <td>{total_price}</td>
      <td>{payment}</td>
      <td>{status}</td>
      <td>
        <Link to={`/transaction/detail/${_id}`}>
          <EyeOutlined className="btn btn-outline-success mr-3" />
        </Link>
        <Link to={`/transaction/edit/${_id}`}>
          <EditOutlined className="btn btn-outline-primary mr-3" />
        </Link>{" "}
        <DeleteOutlined
          onClick={() => handleRemove(_id)}
          className="btn btn-outline-danger"
        />
      </td>
    </tr>
  );
};

export default ListTransaction;
