import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";

import {
  createCategory,
  getCategories,
  removeCategory,
} from "../../functions/category";
import CategoryForm from "../../components/forms/CategoryForm";
import LocalSearch from "../../components/forms/LocalSearch";

const ListCategory = () => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  // searching & filtering
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    loadCategories();
  }, []);
  const loadCategories = () => {
    getCategories().then((c) => setCategories(c.data));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    createCategory({ name }, authtoken)
      .then((res) => {
        setLoading(false);
        setName("");
        toast.success(
          `Category with name "${res.data.name}" created successfully`
        );
        setIsModalVisible(false);
        loadCategories();
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
      });
  };
  const handleRemove = async (id) => {
    if (window.confirm("Are You Sure?")) {
      setLoading(true);
      removeCategory(id, authtoken)
        .then((res) => {
          setLoading(false);
          toast.success(`Success Delete "${res.data.data.name}" Category`);
          loadCategories();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };
  // handle change

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>List Category</h4>
      )}

      <Button type="primary" onClick={showModal}>
        <PlusOutlined /> Create Category
      </Button>
      <Modal
        title="Create Category"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CategoryForm
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
        />
      </Modal>
      <hr />
      <LocalSearch setKeyword={setKeyword} keyword={keyword} />
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Slug</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {categories.filter(searched(keyword)).map((c) => (
            <tr key={c._id}>
              <td> {c.name}</td>
              <td>{c.slug}</td>
              <td>
                <Link
                  to={`/category/edit/${c._id}`}
                  className="btn btn-primary btn-xs"
                >
                  <EditOutlined /> Edit
                </Link>
                |
                <span
                  onClick={() => handleRemove(c._id)}
                  className="btn btn-danger btn-xs"
                >
                  <DeleteOutlined /> Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListCategory;
