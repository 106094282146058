/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { getCustomer, updateCustomer } from "../../functions/customer";
// import { , getCategorySubs } from "../../functions/category";
import AdminNav from "../../components/nav/AdminNav";
import CustomerForm from "../../components/forms/CustomerForm";
// import FileUpload from "../../components/forms/FileUpload";

const CustomerUpdate = ({ history, match }) => {
  //router
  let { id } = match.params;
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  const { fullName, phone, username, email, status } = values;

  useEffect(() => {
    loadCustomer();
  }, []);

  const loadCustomer = () => {
    getCustomer(id, authtoken)
      .then((res) => {
        //console.log("SINGLE PRODUCT", p);
        setValues(res.data);
      })
      .catch((error) => console.log(error));
  };
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateCustomer(id, values, authtoken)
      .then((res) => {
        console.log("UPDATE", res);
        window.alert(`Customer ${res.data.data.fullName} updated successfully`);
        setLoading(false);
        history.push("/customers");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.err);
      });
  };

  return (
    <div className="container-fluid">
      <h4>Edit Customer</h4>
      <hr />

      {/* {JSON.stringify(values)} */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Full Name</label>
          <input
            type="text"
            name="fullName"
            className="form-control col-6"
            value={fullName}
            onChange={handleChange}
            placeholder="FullName"
            autoFocus
            required
          />
        </div>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            className="form-control col-6"
            value={username}
            onChange={handleChange}
            placeholder="Username"
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            className="form-control col-6"
            value={phone}
            onChange={handleChange}
            placeholder="Phone"
            autoFocus
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control col-6"
            value={email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>

        <div className="form-group">
          <label>Status</label>
          <select
            name="status"
            className="form-control col-4"
            onChange={handleChange}
            value={status}
          >
            <option>- Pilih Role-</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        {/* <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="title"
                className="form-control col-6"
                onChange={(e) => handlePassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div> */}
        <br />
        <button className="btn btn-outline-primary" disabled={loading}>
          Save
        </button>
      </form>
    </div>
  );
};

export default CustomerUpdate;
