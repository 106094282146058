import axios from "axios";

export const createPriceList = async (authtoken, body) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/price-list/create`,
    body,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
        "Content-type": "application/json",
      },
    }
  );
};

export const getPriceList = async (authtoken) => {
  console.log("TOKEN", authtoken);
  return await axios.get(`${process.env.REACT_APP_API}/admin/price-list`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};

export const updatePriceList = async (authtoken, body, id, name) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/price-list/update/${id}/${name}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
        "Content-type": "application/json",
      },
    }
  );
};

export const deleteContentPriceList = async (authtoken, id, name) => {
  console.log(authtoken);
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/price-list/delete/${id}/${name}`,
    JSON.stringify({}),
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};

export const deletePriceList = async (authtoken, id) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/price-list/delete-all/${id}`,
    JSON.stringify({}),
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
