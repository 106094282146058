/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Button, Image, Upload } from "antd";
import { getTechnician, updateTechnician } from "../../functions/technician";
import AdminNav from "../../components/nav/AdminNav";
import AdminForm from "../../components/forms/AdminForm";
import imageCompression from "browser-image-compression";
// import FileUpload from "../../components/forms/FileUpload";

const TechnicianUpdate = ({ history, match }) => {
  //router
  let { id } = match.params;
  const [values, setValues] = useState({});

  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state) => ({ ...state }));
  const [preview, setPreview] = useState(null);
  const { fullName, email, mainPhone, status } = values;
  const authtoken = localStorage.getItem("token");

  console.log("VALUES", values);
  useEffect(() => {
    loadAdmin();
  }, []);

  const loadAdmin = () => {
    getTechnician(id, authtoken)
      .then((res) => {
        //console.log("SINGLE PRODUCT", p);
        setValues(res.data.technician);
        setPreview(
          process.env.REACT_APP_IMAGE_API +
            "/" +
            res.data.technician.profilePicture
        );
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    console.log("aaa", e.target.name, e.target.value);

    setValues({
      ...values,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value,
    });

    if (e.target.type === "file") {
      let url = URL.createObjectURL(e.target.files[0]);
      setPreview(url);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 500,
      fileType: "image/jpeg",
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(
        values.profilePicture[0],
        options
      );
      setLoading(true);
      const formData = new FormData();
      if (typeof values.profilePicture == "object") {
        const name = compressedFile.name.split(".");
        name[name.length - 1] = "jpeg";
        compressedFile.name = name.join(".");
        console.log("COMPRESS", compressedFile);
        formData.append("profilePicture", compressedFile, compressedFile.name);
      }
      formData.append("profilePicture", values.profilePicture);
      formData.append("fullName", values.fullName);
      formData.append("email", values.email);
      formData.append("mainPhone", values.mainPhone);
      formData.append("status", values.status);
      await updateTechnician(id, formData, authtoken)
        .then((res) => {
          console.log("UPDATE", res);
          window.alert(`Admin ${res.data.data.fullName} updated successfully`);
          setLoading(false);
          history.push("/technicians");
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // const upload = {
  //   beforeUpload: (file) => {
  //     const isPNG = file.type === "image/png";
  //     if (!isPNG) {
  //       message.error(`${file.name} is not a png file`);
  //     }
  //     return isPNG || Upload.LIST_IGNORE;
  //   },
  //   onChange: (info) => {
  //     console.log(info);
  //   },
  //   listType: "picture",
  //   action: `${process.env.REACT_APP_API}/admin/technician/update/${id}`,
  //   headers: {
  //     "content-type": "multipart/form-data",
  //     Authorization: "Bearer " + authtoken,
  //   },
  // };

  return (
    <div className="container-fluid">
      <h4>Edit Technician</h4>
      <hr />

      {/* {JSON.stringify(values)} */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Profile Picture</label>
          <br />
          <Image
            src={preview || values.profilePicture}
            width={200}
            height={200}
          />
          <label
            className="inp inp_e"
            style={{
              width: 300,
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <input
              type="file"
              onChange={handleChange}
              name="profilePicture"
              accept="image/png, image/jpeg, image/jpg, image/gif"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            />
            <span
              style={{
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              Add Picture
              <img
                src="/assets/Camera.png"
                alt=""
                style={{
                  transform: "translateY(-2px)",
                  width: 30,
                  marginLeft: 10,
                }}
              />
            </span>
          </label>
        </div>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="fullName"
            className="form-control col-6"
            value={fullName}
            onChange={handleChange}
            placeholder="Username"
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control col-6"
            value={email}
            onChange={handleChange}
            placeholder="Email"
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="phone"
            name="mainPhone"
            className="form-control col-6"
            value={mainPhone}
            onChange={handleChange}
            placeholder="Phone"
          />
        </div>

        <div className="form-group">
          <label>Status</label>
          <select
            name="status"
            className="form-control col-4"
            onChange={handleChange}
            value={status}
          >
            <option>- Pilih Status-</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
            <option value="reject">Reject</option>
            <option value="blocked">Blocked</option>
          </select>
        </div>

        <br />
        <button className="btn btn-outline-primary" disabled={loading}>
          Save
        </button>
      </form>
    </div>
  );
};

export default TechnicianUpdate;
