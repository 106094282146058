/* eslint-disable no-undef */
import axios from "axios";

export const createProduct = async (product, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/product/create`,
    product,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
};
export const getProductsByCount = async (count) => {
  return await axios.get(`${process.env.REACT_APP_API}/products/${count}`);
};
export const fetchProductsByFilter = async (arg) => {
  return await axios.post(`${process.env.REACT_APP_API}/search/filters`, arg);
};
export const getProduct = async (slug) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/product/${slug}`);
};
export const getRelated = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/product/related/${productId}`
  );
};
export const removeProduct = async (slug, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/product/${slug}`,

    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateProduct = async (slug, product, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/product/${slug}`,
    product,
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const getProducts = async (sort, order, page, authtoken) => {
  // console.log("TOKEEEEEE", authtoken);
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/products`,
    {
      sort,
      order,
      page,
    },
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getProductTotal = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/productCount`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const productStar = async (productId, star, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/product/star/${productId}`,
    { star },
    {
      headers: {
        authtoken,
      },
    }
  );
};
