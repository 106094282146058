import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Spin, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import AdminNav from "../../components/nav/AdminNav";
import ListTechnician from "../../components/lists/ListTechnician";
import {
  getTechnicians,
  removeTechnician,
  getTechnicianTotal,
  searchTechnicianByName,
  searchTechnicianByFilter,
} from "../../functions/technician";
import { getSkills } from "../../functions/skill";
import { getAllCities } from "../../functions/cities";
// import { getAllBpaysUser } from "../../functions/bpay";

const TechnicianList = () => {
  const { Search } = Input;
  const { Option } = Select;
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [technicianCount, setTechnicianCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState(null);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    getTechnicianTotal(authtoken).then((res) => {
      console.log("Customer COUNT", res.data);
      setTechnicianCount(res.data);
    });
    getSkills()
      .then((res) => setSkills(res.data))
      .catch((err) => console.log(err));

    getAllCities(authtoken)
      .then((res) => setCities(res.data.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const loadTechnicianList = () => {
    setLoading(true);
    getTechnicians("_id", "desc", page, authtoken)
      .then((res) => {
        setAdmins(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleRemove = (slug) => {
    let answer = window.confirm("Are you sure?");
    if (answer) {
      // console.log("send delete request", slug);
      removeTechnician(slug, authtoken)
        .then((res) => {
          loadTechnicianList();
          toast.error(
            `Admin with name ${res.data.data.fullName} deleted successfully`
          );
        })
        .catch((err) => {
          // if (err.response.status === 400) {
          //   toast.error(err.response.data);
          // }
          console.log(err);
        });
    }
  };
  const technicianSearch = async () => {
    // Search Technician
    // console.log(value.length);
    if (searchText.length != 0) {
      await searchTechnicianByName("_id", "desc", page, searchText, authtoken)
        .then((res) => setAdmins(res.data.data))
        .catch((err) => console.log(err));
    } else {
      loadTechnicianList();
      setSearchText("");
    }
  };

  const technicianByFilter = async () => {
    await searchTechnicianByFilter(
      "_id",
      "desc",
      page,
      selectedSkill,
      selectedCities,
      status,
      authtoken
    )
      .then((res) => setAdmins(res.data.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (searchText == "") {
      loadTechnicianList();
    } else {
      technicianSearch();
    }
  }, [searchText, page]);

  useEffect(() => {
    if (selectedSkill != null || status != "" || selectedCities != null) {
      technicianByFilter();
    } else {
      loadTechnicianList();
    }
  }, [selectedSkill, page, selectedCities]);
  console.log("status", typeof status);
  console.log("SKILLS", selectedSkill);
  return (
    <>
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>All Technicians (Total: {technicianCount} Teknisi)</h4>
      )}
      {/* <Link to="/partner/create">
            <button className="btn btn-md btn-outline-primary">
              Create Technician
            </button>
          </Link> */}

      <hr />
      <Search
        size="large"
        placeholder="Cari Data"
        onSearch={technicianSearch}
        enterButton
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div style={{ flexDirection: "row", marginTop: 10, marginBottom: 10 }}>
        <Select
          placeholder="Filter By Status"
          name="status"
          onChange={(value) => setStatus(value)}
          style={{ width: 100 }}
        >
          <Option value="">Pilih Status</Option>
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
          <Option value="pending">Pending</Option>
          <Option value="reject">Reject</Option>
          <Option value="blocked">Blocked</Option>
        </Select>
        <Select
          placeholder="Filter By Skill"
          name="skill"
          onChange={(value) => setSelectedSkill(value)}
          style={{ marginLeft: 20 }}
        >
          {skills.map((skill) => (
            <Option key={skill._id} value={skill._id}>
              {skill.name}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Filter By City"
          name="city"
          onChange={(value) => setSelectedCities(value)}
          style={{ marginLeft: 20, width: 250 }}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {cities.map((city) => (
            <Option key={city._id} value={city._id}>
              {city.name}
            </Option>
          ))}
        </Select>
      </div>
      <table className="table table-striped table-bordered table-responsive">
        <thead>
          <tr>
            <th>#</th>
            <th>No.</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>City</th>
            <th>Skills</th>
            <th>Join Date</th>
            <th>Status</th>
            <th>Saldo</th>
            <th>Options</th>
          </tr>
        </thead>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        ) : (
          <tbody>
            {admins.map((admin, index) => (
              <ListTechnician
                no={index}
                admin={admin}
                handleRemove={handleRemove}
                key={admin._id}
              />
            ))}
          </tbody>
        )}
      </table>
      <Pagination
        current={page}
        total={(technicianCount / 10) * 5}
        onChange={(value) => setPage(value)}
      />
    </>
  );
};

export default TechnicianList;
