/* eslint-disable react/prop-types */
import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SkillForm = ({
  handleSubmit,
  name,
  setName,
  status,
  setStatus,
  categories,
  // selectedCate,
  setSelectedCate,
}) => {
  const children = [];
  categories.map((i) => {
    children.push(
      <Option key={i._id} value={i._id}>
        {i.name}
      </Option>
    );
  });
  const handleChange = (value) => {
    setSelectedCate([...value]);
  };
  // const handleStatus = (value) => {
  //   setStatus(value);
  // };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4">
          <label>Name</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Skill Name"
            autoFocus
            required
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-4">
          <label>Category</label>
        </div>
        <div className="col-8">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            // defaultValue={selectedCate}
            onChange={handleChange}
          >
            {children}
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Status</label>
        </div>
        <div className="col-8">
          <select
            className="form-control col-8"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option>- Select Status -</option>
            <option value="active" selected={status === "active"}>
              Active
            </option>
            <option value="inactive" selected={status === "inactive"}>
              Inactive
            </option>
          </select>
        </div>
      </div>
      <br />
      <button className="btn btn-outline-primary">Save</button>
    </form>
  );
};

export default SkillForm;
