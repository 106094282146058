/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { createAdmin } from "../../functions/admin";

const AdminForm = ({ values, categories }) => {
  const history = useHistory();
  // console.log(values.username);
  const name = values.username;

  const [username, setUsername] = useState(name);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  // const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const handleSubmit = (e) => {
    e.preventDefault();
    const admin = { username, email, password, role };
    createAdmin(admin, authtoken)
      .then((res) => {
        history.push("/admins");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUsername = (e) => {
    setUsername(e);
  };
  const handleEmail = (e) => {
    setEmail(e);
  };
  const handlePassword = (e) => {
    setPassword(e);
  };
  const handleRole = (e) => {
    setRole(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Username</label>
        <input
          type="text"
          name="title"
          className="form-control col-6"
          value={username}
          onChange={(e) => handleUsername(e.target.value)}
          placeholder="Username"
          autoFocus
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="title"
          className="form-control col-6"
          value={email}
          onChange={(e) => handleEmail(e.target.value)}
          placeholder="Email"
          required
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          name="title"
          className="form-control col-6"
          value={password}
          onChange={(e) => handlePassword(e.target.value)}
          placeholder="Password"
          required
        />
      </div>
      <div className="form-group">
        <label>Role</label>
        <select
          className="form-control col-4"
          onChange={(e) => handleRole(e.target.value)}
        >
          <option>- Pilih Role-</option>
          <option>Admin</option>
          <option>Author</option>
          <option>Finance</option>
          <option>Accounting</option>
        </select>
        <p>{JSON.stringify(role)}</p>
      </div>
      <br />
      <button className="btn btn-outline-primary" disabled={loading}>
        Save
      </button>
    </form>
  );
};

export default AdminForm;
