import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";

import {
  createSkill,
  getSkills,
  removeSkill,
  getSubCategories,
} from "../../functions/skill";
import AdminNav from "../../components/nav/AdminNav";
import SkillForm from "../../components/forms/SkillForm";
import LocalSearch from "../../components/forms/LocalSearch";

const SkillList = () => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [skillId, setSkillId] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCate, setSelectedCate] = useState([]);
  // searching & filtering
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    loadCategories();
    loadSkills();
  }, []);
  const loadSkills = () => getSkills().then((c) => setSkills(c.data));
  const loadCategories = () =>
    getSubCategories().then((s) => setCategories(s.data));
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    createSkill({ name, selectedCate, status }, authtoken)
      .then((res) => {
        setLoading(false);
        setName("");
        setSelectedCate([]);
        setStatus("");
        toast.success(
          `Skill with name "${res.data.name}" created successfully`
        );
        loadCategories();
        loadSkills();
        handleCancel();
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        // if (err.response.status === 400) {
        toast.error(err);
        // }
      });
  };
  const handleRemove = async (id) => {
    if (window.confirm("Are You Sure?")) {
      setLoading(true);
      removeSkill(id, authtoken)
        .then((res) => {
          setLoading(false);
          toast.success(`Success Delete "${res.data.data.name}" Skill`);
          loadCategories();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };
  // handle change

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>List Skill</h4>
      )}

      <Button type="primary" onClick={showModal}>
        <PlusOutlined /> Create Skill
      </Button>

      <Modal
        title="Create Skill"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {JSON.stringify(selectedCate)}({status})
        <SkillForm
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
          code={code}
          setCode={setCode}
          skillId={skillId}
          setSkillId={setSkillId}
          setStatus={setStatus}
          status={status}
          categories={categories}
          selectedCate={selectedCate}
          setSelectedCate={setSelectedCate}
        />
      </Modal>
      <hr />
      <LocalSearch setKeyword={setKeyword} keyword={keyword} />
      {/* {JSON.stringify(skills)} */}
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Sub Category</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {skills.filter(searched(keyword)).map((c) => (
            <tr key={c._id}>
              <td> {c.name}</td>
              <td>{c.subCategories.map((cat) => cat.name + ", ")}</td>
              <td>
                <Link
                  to={`/skill/edit/${c._id}`}
                  className="btn btn-primary btn-xs"
                >
                  <EditOutlined /> Edit
                </Link>
                |
                <span
                  onClick={() => handleRemove(c._id)}
                  className="btn btn-danger btn-xs"
                >
                  <DeleteOutlined /> Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkillList;
