/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";

const CategoryForm = ({ handleSubmit, name, setName }) => (
  <form onSubmit={handleSubmit}>
    <label>Name</label>
    <input
      type="text"
      className="form-control col-6"
      value={name}
      onChange={(e) => setName(e.target.value)}
      placeholder="Category Name"
      autoFocus
      required
    />
    <br />
    <button className="btn btn-outline-primary">Save</button>{" "}
    <Link to={`/categories`} className="btn btn-outline-danger btn-xs">
      Cancel
    </Link>
    {/* <button className="btn btn-outline-danger">Cancle</button> */}
  </form>
);

export default CategoryForm;
