import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from "antd";

import AdminNav from "../../components/nav/AdminNav";
import AdminProductList from "../../components/lists/AdminProductList";
import {
  getProducts,
  removeProduct,
  getProductTotal,
} from "../../functions/product";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [productCount, setProductCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  useEffect(() => {
    loadAllProducts();
  }, [page]);

  useEffect(() => {
    getProductTotal(authtoken).then((res) => {
      console.log("Product COUNT", res.data);
      setProductCount(res.data);
      document.title = "Product List - TSOL Admin";
    });
  }, []);
  const loadAllProducts = () => {
    setLoading(true);
    getProducts("_id", "desc", page, authtoken)
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
        console.log("nyari image ", res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleRemove = (slug) => {
    let answer = window.confirm("Are you sure?");
    if (answer) {
      // console.log("send delete request", slug);
      removeProduct(slug, authtoken)
        .then((res) => {
          loadAllProducts();
          toast.error(
            `Product with name ${res.data.title} deleted successfully`
          );
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data);
          }
          //console.log(err)
        });
    }
  };
  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>All Products</h4>
      )}
      <Link to="/product/create">
        <button className="btn btn-md btn-outline-primary">
          Create Product
        </button>
      </Link>

      <hr />
      <div className="row">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>No.</th>
              <th>Image</th>
              <th>Name</th>
              <th>Categories {">"} Subcategory</th>
              <th>Brand</th>
              <th>Created</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <AdminProductList
                no={index}
                product={product}
                handleRemove={handleRemove}
                key={product._id}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          current={page}
          total={(productCount / 10) * 5}
          onChange={(value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default ProductList;
