/* eslint-disable react/prop-types */
import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Moment from "moment";
import { Link } from "react-router-dom";

import defaultImage from "../../images/default.png";
import { REACT_IMAGE_API } from "../../config/env";

const AdminProductList = ({ product, handleRemove, no }) => {
  const {
    productName,
    brand,
    //description,
    category,
    subcategory,
    images,
    //category,
    createdAt,
    _id,
  } = product;
  // console.log(product, "kkskksk");
  // console.log(images, "images");
  return (
    <tr>
      <td>
        <input type="checkbox" name="" id="" />
      </td>
      <td>{no + 1}</td>
      <td>
        <img
          alt={productName}
          src={
            images != ""
              ? `${REACT_IMAGE_API}/products/${images}`
              : defaultImage
            // ? images
            // : // ? `${REACT_IMAGE_API}/${images[0]}`
            //   defaultImage
          }
          style={{ height: 65 }}
        />
      </td>
      <td>{productName}</td>
      <td>
        {category.name} {">"} {subcategory.name}
      </td>
      <td>{brand}</td>
      <td>
        {Moment(createdAt).format("YYYY/MM/DD")} at{" "}
        {Moment(createdAt).format("h:mm:ss a")}
      </td>
      <td>
        <Link to={`/admin/product/${_id}`}>
          <EditOutlined className="btn btn-outline-primary mr-3" />
        </Link>{" "}
        <DeleteOutlined
          onClick={() => handleRemove(_id)}
          className="btn btn-outline-danger"
        />
      </td>
    </tr>
  );
};

export default AdminProductList;
