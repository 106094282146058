/* eslint-disable no-undef */
import axios from "axios";

export const createCustomer = async (product, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/product`,
    product,
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const getCustomersByCount = async (count) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/listCustomer/${count}`
  );
};
export const getAllCustomers = async (sort, order, page, authtoken) => {
  const data = { sort, order, page };
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/allCustomer`,
    data,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const fetchCustomersByFilter = async (arg) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/search/filters`,
    arg
  );
};

export const getCustomer = async (id, authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/customer/${id}`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const getRelated = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/product/related/${productId}`
  );
};
export const removeCustomer = async (slug, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/product/${slug}`,

    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateCustomer = async (id, data, authtoken) => {
  return await axios.patch(
    `${process.env.REACT_APP_API}/admin/customer/update/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getCustomers = async (sort, order, page) => {
  return await axios.post(`${process.env.REACT_APP_API}/admin/products`, {
    sort,
    order,
    page,
  });
};
export const getCustomerTotal = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/customerCount`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const productStar = async (productId, star, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/product/star/${productId}`,
    { star },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const searchCustomerByName = async (name, authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/customer/search/${name}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
