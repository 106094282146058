import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { createAdmin } from "../../functions/admin";
import { getCategories, getCategorySubs } from "../../functions/category";
import AdminNav from "../../components/nav/AdminNav";
import CustomerForm from "../../components/forms/CustomerForm";
// import FileUpload from "../../components/forms/FileUpload";

const CustomerCreate = () => {
  const [values, setValues] = useState({
    title: "Admin Test",
    description: "Admin Test",
    price: "12000000",
    sale_price: "0",
    category: "",
    subcategory: [],
    categories: [],
    shipping: "",
    quantity: "100",
    images: [],
    colors: ["Black", "white", "Red", "Blue", "Green"],
    color: "",
    brands: ["Microsoft", "HP", "Canon", "Samsung"],
    brand: "",
  });
  const [subOptions, setSubOptions] = useState([]);
  const [subShow, setSubShow] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  useEffect(() => {
    loadCategories();
  }, []);
  const loadCategories = () =>
    getCategories().then((c) => {
      //console.log("GET CATEGORIES", c);
      setCategories(c.data);
    });
  const handleSubmit = (e) => {
    e.preventDefault();
    createAdmin(values, authtoken)
      .then((res) => {
        //console.log(res);
        window.alert(`Admin ${res.data.title} created successfully`);
        window.location.reload();
      })
      .catch((err) => {
        // if (err.response.status === 400) {
        //   toast.error(err.response.data);
        // }
        if (err.response.data.err.includes("duplicate")) {
          toast.error("Duplicate Admin Title/Slug");
        } else {
          toast.error(err.response.data.err);
        }
      });
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    //console.log(e.target.name, "----", e.target.value);
  };
  const handleCategoryChange = (e) => {
    e.preventDefault();
    console.log("CLICKED CATEGORY", e.target.value);
    setValues({ ...values, subcategory: [], category: e.target.value });
    getCategorySubs(e.target.value).then((res) => {
      console.log("SUB OPTIONS ON CATEGORY CLICK -->", res.data);
      setSubOptions(res.data);
    });
    setSubShow(true);
  };
  return (
    <div className="container-fluid">
      <h4>Create Customer</h4>
      <hr />

      <CustomerForm
        values={values}
        categories={categories}
        setValues={setValues}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleCategoryChange={handleCategoryChange}
        subOptions={subOptions}
        subShow={subShow}
      />
    </div>
  );
};

export default CustomerCreate;
