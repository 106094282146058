/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import Moment from "moment";
import "moment/locale/id";
import { Link } from "react-router-dom";
import { getAllBpaysUser } from "../../functions/bpay";
import { currency } from "../../functions/lib";

// import defaultImage from "../../images/default.png";

const ListTechnician = ({ admin, handleRemove, no }) => {
  const {
    _id,
    fullName,
    email,
    mainPhone,
    skills,
    status,
    created_at,
    city,
    idCard,
  } = admin;
  Moment.locale("id");
  const [balance, setBalance] = useState(0);
  const authtoken = localStorage.getItem("token");
  const getBpays = () => {
    let body = JSON.stringify({
      role: "technician",
      account_id: _id,
    });
    getAllBpaysUser(authtoken, body).then((res) => {
      console.log(res.data.balance);
      setBalance(res.data.balance);
    });
  };

  useEffect(() => {
    getBpays();
  }, []);
  console.log("show engineer", admin);
  return (
    <tr>
      <td>
        <input type="checkbox" name="" id="" />
      </td>
      <td>{no + 1}</td>
      <td>{fullName}</td>
      <td>{mainPhone}</td>
      <td>{email}</td>
      <td>{city.name}</td>
      <td>
        {skills.map((d) => (
          <>
            {d.name}
            {", "}
          </>
        ))}
      </td>
      <td>{Moment(created_at).format("LLLL")}</td>
      <td>{status}</td>
      <td>{currency(balance, "Rp ") || "Rp 0"}</td>
      <td>
        {idCard && (
          <Link to={`/technician/detail/${_id}`}>
            <EyeOutlined className="btn btn-outline-success mr-3" />
          </Link>
        )}{" "}
        <Link to={`/technician/edit/${_id}`}>
          <EditOutlined className="btn btn-outline-primary mr-3" />
        </Link>{" "}
        <DeleteOutlined
          onClick={() => handleRemove(_id)}
          className="btn btn-outline-danger"
        />
      </td>
    </tr>
  );
};

export default ListTechnician;
