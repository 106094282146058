/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { createPartner } from "../../functions/partner";

const PartnerForm = ({ handleChange, setValues, values, categories }) => {
  const history = useHistory();
  // console.log(values.username);
  // const name = values.username;

  // const [username, setUsername] = useState(name);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [role, setRole] = useState("");
  // const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const handleSubmit = (e) => {
    e.preventDefault();

    createPartner(values, authtoken)
      .then((res) => {
        history.push("/partners");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Company Name</label>
        <input
          type="text"
          name="companyName"
          className="form-control col-6"
          value={values.companyName}
          onChange={handleChange}
          placeholder="Company Name"
          autoFocus
          required
        />
      </div>
      <div className="form-group">
        <label>Company Email</label>
        <input
          type="text"
          name="companyEmail"
          className="form-control col-6"
          value={values.companyEmail}
          onChange={handleChange}
          placeholder="Company Email"
          required
        />
      </div>
      <div className="form-group">
        <label>Company Phone</label>
        <input
          type="text"
          name="companyPhone"
          className="form-control col-6"
          value={values.companyPhone}
          onChange={handleChange}
          placeholder="Company Phone"
          required
        />
      </div>
      <div className="form-group">
        <label>Username</label>
        <input
          type="text"
          name="username"
          className="form-control col-6"
          value={values.username}
          onChange={handleChange}
          placeholder="Username"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          name="email"
          className="form-control col-6"
          value={values.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          name="password"
          className="form-control col-6"
          value={values.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
      </div>
      <div className="form-group">
        <p>{JSON.stringify(values)}</p>
      </div>
      <br />
      <button className="btn btn-outline-primary" disabled={loading}>
        Save
      </button>
    </form>
  );
};

export default PartnerForm;
