/* eslint-disable react/prop-types */
import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Moment from "moment";
import { Link } from "react-router-dom";

// import defaultImage from "../../images/default.png";

const ListAdmin = ({ admin, handleRemove }) => {
  const { _id, username, email, role, status, created_at } = admin;
  return (
    <tr>
      <td>
        <input type="checkbox" name="" id="" />
      </td>
      <td>{username}</td>
      <td>{email}</td>
      <td>{role}</td>
      <td>{status}</td>
      <td>
        {Moment(created_at).format("YYYY/MM/DD")} at{" "}
        {Moment(created_at).format("h:mm:ss a")}
      </td>
      <td>
        <Link to={`/admin/edit/${_id}`}>
          <EditOutlined className="btn btn-outline-primary mr-3" />
        </Link>{" "}
        <DeleteOutlined
          onClick={() => handleRemove(_id)}
          className="btn btn-outline-danger"
        />
      </td>
    </tr>
  );
};

export default ListAdmin;
