/* eslint-disable no-undef */
import axios from "axios";

export const listTechnician = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/listTechnician`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
