import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Layout, Menu, Breadcrumb, Row, Col } from "antd";
const { SubMenu } = Menu;
const { Header, Footer, Content, Sider } = Layout;
import { ToastContainer } from "react-toastify";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

import LoadingToRedirect from "./LoadingToRedirect";
import { currentAdmin } from "../../functions/auth";
import Heads from "../layouts/Header";
import Foots from "../layouts/Footer";
import AdminNav from "../nav/AdminNav";
// import logo from "../../assets/images/logo-admin.png";

const AdminRoute = ({ ...rest }) => {
  const [ok, setOk] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const toggleCollapsed = () => {
    if (collapse) {
      setCollapse(false);
    } else {
      setCollapse(true);
    }
  };
  useEffect(() => {
    if (!ok) {
      // console.log("USER TOKEN", user);
      const authtoken = localStorage.getItem("token");
      console.log("AUTH TOKEN", authtoken);
      currentAdmin(authtoken)
        .then((res) => {
          console.log(res.data);
          if (res.data.name === "JsonWebTokenError") {
            setOk(false);
          } else {
            setOk(true);
          }
        })
        .catch(() => setOk(false));
    }
  }, [ok]);
  // console.log("OK", ok);
  const d = new Date();
  let year = d.getFullYear();
  return ok ? (
    <Layout className="site-layout-background">
      <Header
        className="site-layout-background"
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <div></div>
        <Menu theme="light" mode="horizontal" style={{ marginLeft: -30 }}>
          <Menu.Item key="0" style={{ fontSize: 20, marginLeft: -25 }}>
            Admin Service Hub
          </Menu.Item>
          <Menu.Item key="1">Home</Menu.Item>
          <Menu.Item key="2">Logout</Menu.Item>
          <Menu.Item key="3">About</Menu.Item>
        </Menu>
      </Header>
      <Layout className="site-layout-background">
        <Sider
          collapsed={collapse}
          onCollapse={toggleCollapsed}
          breakpoint="lg"
          style={{ marginTop: 65 }}
          className="site-layout-background"
        >
          <AdminNav />
        </Sider>
        <Layout
          className="site-layout-background"
          style={{ marginTop: 65 }}
          breakpoint="lg"
        >
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              marginLeft: 20,
              width: "100%",
              // marginBottom: 50,
            }}
          >
            <Route {...rest} />
          </Content>
        </Layout>
      </Layout>
      <Layout
        style={{
          textAlign: "center",
          bottom: 0,
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          marginTop: 100,
        }}
      >
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Service Hub © {year} PT. Kalam Saba Adidaya
        </Footer>
      </Layout>
    </Layout>
  ) : (
    <LoadingToRedirect />
  );
};

export default AdminRoute;
