/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import AdminNav from "../../components/nav/AdminNav";
// import CategoryForm from "../../components/forms/CategoryForm";
import PriceListForm from "../../components/forms/PriceListForm";
import { updatePriceList } from "../../functions/pricelist";

const PriceListUpdate = (props) => {
  const { match, location, history } = props;

  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [data, setData] = useState(location.state);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = JSON.stringify({
      content: {
        "content.$.name": data.name,
        "content.$.price": data.price,
        "content.$.detail": data.detail,
      },
    });
    // console.log(name);
    setLoading(true);
    updatePriceList(authtoken, body, match.params.id, location.state.name)
      .then(() => {
        setLoading(false);
        // setName("");

        history.goBack();
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
      });
  };

  console.log("data", data);

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <>
          <h4>Update Price List</h4>

          <PriceListForm
            handleSubmit={handleSubmit}
            data={data}
            setData={setData}
          />
        </>
      )}
    </div>
  );
};

export default PriceListUpdate;
