/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Button, Image } from "antd";
import { MailOutlined, GoogleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { loginWithEmail } from "../../functions/auth";
import "../../assets/css/style.css";
import "../../assets/fonts/icomoon/style.css";
import background from "../../assets/images/undraw_file_sync_ot38.svg";

const Login = ({ history }) => {
  const [email, setEmail] = useState("admin@site.com");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => ({ ...state }));

  // useEffect(() => {
  //   console.log("USER ON LOGIN", user);
  //   let intended = history.location.state;
  //   if (intended) {
  //     return;
  //   } else {
  //     if (user && user.token) {
  //       history.push("/");
  //     }
  //   }
  // }, [user, history]);

  let dispatch = useDispatch();
  const roleBasedRedirect = (res) => {
    // check if intended
    let intended = history.location.state;
    if (intended) {
      history.push(intended.from);
    } else {
      if (res.data.user.role === "admin") {
        history.push("/dashboard");
      } else {
        history.push("/user/history");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // console.table(email, password);
    try {
      const result = await loginWithEmail(email, password)
        .then((res) => {
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              _id: res.data.user._id,
              name: res.data.user.username,
              email: res.data.user.email,
              role: res.data.user.role,
              token: res.data.access_token,
            },
          });
          localStorage.setItem("token", res.data.access_token);
          roleBasedRedirect(res);
        })
        .catch((err) => console.log(err));
      console.log(result);
      //history.push("/");
    } catch (error) {
      //console.log(error);

      toast.error(error.message);
    }
  };

  return (
    <div className="content">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-md-2">
            <img
              src={background}
              alt="Background"
              className="img-fluid"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className="col-md-6 contents">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="mb-4">
                  <h3>
                    Sign In to <strong>Service Hub</strong>
                  </h3>
                  <p className="mb-4">Connecting The Services</p>
                </div>
                <form>
                  <div className="form-group first">
                    {/* <label>Username</label> */}
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group last mb-4">
                    {/* <label for="password">Password</label> */}
                    <input
                      className="form-control"
                      id="password"
                      type="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  {/* <div className="d-flex mb-5 align-items-center">
                    <label className="control control--checkbox mb-0">
                      <span className="caption">Remember me</span>
                      <input type="checkbox" checked="checked" />
                      <div className="control__indicator"></div>
                    </label>
                    <span className="ml-auto">
                      <a href="/#" className="forgot-pass">
                        Forgot Password
                      </a>
                    </span>
                  </div> */}

                  {/* <input type="submit" value="Log In" className="btn text-white btn-block btn-primary"/> */}
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    className="mb-3"
                    block
                    shape="round"
                    icon={<MailOutlined />}
                    size="large"
                    disabled={!email || password.length < 6 || loading}
                  >
                    Login
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
