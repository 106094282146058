import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Spin, Input } from "antd";

import AdminNav from "../../components/nav/AdminNav";
import ListPartner from "../../components/lists/ListPartner";
import {
  removePartner,
  getPartners,
  getPartnerTotal,
  searchPartnerByName,
} from "../../functions/partner";

const PartnerList = () => {
  const { Search } = Input;
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [partnerCount, setPartnerCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    getPartnerTotal(authtoken).then((res) => {
      console.log("Partner COUNT", res.data);
      setPartnerCount(res.data);
    });
  }, []);
  const loadPartnerList = () => {
    setLoading(true);
    getPartners("_id", "asc", page, authtoken)
      .then((res) => {
        setAdmins(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleRemove = (id) => {
    let answer = window.confirm("Are you sure?");
    if (answer) {
      // console.log("send delete request", slug);
      removePartner(id, authtoken)
        .then((res) => {
          loadPartnerList();
          toast.error(
            `Partner with name ${res.data.data.fullName} deleted successfully`
          );
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data);
          }
        });
    }
  };

  const partnerSearch = async () => {
    if (searchText.length != 0) {
      await searchPartnerByName(searchText, authtoken)
        .then((res) => setAdmins(res.data.data))
        .catch((err) => console.log(err));
    } else {
      loadPartnerList();
      setSearchText("");
    }
  };

  useEffect(() => {
    if (searchText == "") {
      loadPartnerList();
    } else {
      partnerSearch();
    }
  }, [page, searchText]);
  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>All Partners (Total : {partnerCount})</h4>
      )}
      <Link to="/partner/create">
        <button className="btn btn-md btn-outline-primary">
          Create Partner
        </button>
      </Link>
      <Search
        size="large"
        placeholder="Cari Data"
        onSearch={partnerSearch}
        enterButton
        onChange={(e) => setSearchText(e.target.value)}
      />
      <hr />
      <div className="row">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Store Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Status</th>
              <th>Saldo</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <ListPartner
                admin={admin}
                handleRemove={handleRemove}
                key={admin._id}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          current={page}
          total={(partnerCount / 10) * 5}
          onChange={(value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default PartnerList;
