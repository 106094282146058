/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";

const PriceListForm = ({ handleSubmit, data, setData }) => (
  <form onSubmit={handleSubmit}>
    <label>Type Service</label>
    <input
      type="text"
      className="form-control col-6"
      value={data.name}
      onChange={(e) =>
        setData({
          ...data,
          name: e.target.value,
        })
      }
      placeholder="Type Service"
      autoFocus
      required
    />
    <br />
    <label>Price</label>
    <input
      type="text"
      className="form-control col-6"
      value={data.price}
      onChange={(e) =>
        setData({
          ...data,
          price: e.target.value,
        })
      }
      placeholder="Price"
      autoFocus
      required
    />
    <br />
    <label>Detail</label>
    <input
      type="text"
      className="form-control col-6"
      value={data.detail}
      onChange={(e) =>
        setData({
          ...data,
          detail: e.target.value,
        })
      }
      placeholder="Detail"
      autoFocus
      required
    />
    <br />
    <button className="btn btn-outline-primary">Save</button>{" "}
    <Link to={`/price-list`} className="btn btn-outline-danger btn-xs">
      Cancel
    </Link>
    {/* <button className="btn btn-outline-danger">Cancle</button> */}
  </form>
);

export default PriceListForm;
