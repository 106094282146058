/* eslint-disable no-undef */
import axios from "axios";

export const getBrands = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/brand/listAll`);
};
export const getBrand = async (id) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/brand/${id}`);
};
export const removeBrand = async (slug, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/brand/${slug}`,

    {
      headers: {
        authtoken,
      },
    }
  );
};
export const updateBrand = async (id, brand, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/brand/${id}`,
    brand,
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const createBrand = async (brand, authtoken) => {
  console.log("BANK", brand);
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/brand/create`,
    brand,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
