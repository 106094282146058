/* eslint-disable no-undef */
import axios from "axios";

export const getCategories = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/categories`);
};

export const getCategory = async (id, authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/category/${id}`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const removeCategory = async (id, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/category/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateCategory = async (id, category, authtoken) => {
  return await axios.patch(
    `${process.env.REACT_APP_API}/admin/category/update/${id}`,
    category,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};

export const createCategory = async (category, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/category/create`,
    category,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};

export const getCategorySubs = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/subcategory/parent/${_id}`
  );
};
