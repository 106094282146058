/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createRef } from "react";
import { Select } from "antd";
const { Option } = Select;

const ProductForm = ({
  handleSubmit,
  handleChange,
  handleCategoryChange,
  values,
  loading,
  setLoading,
  setValues,
  subOptions,
  subShow,
  categories,
  arrayOfSub,
  setArrayOfSub,
  selectedCategory,
  brands,
  handleChangeFile,
}) => {
  const inputRef = createRef();
  const {
    productName,
    description,
    category,
    //images,
    // brands,
    brand,
    type,
    unit,
    status,
  } = values;

  return (
    <form onSubmit={handleSubmit}>
      {/* {JSON.stringify(values)} */}
      <div className="form-group">
        <label>Foto Produk</label>
        <label className="btn btn-primary btn-raised">
          Foto Produk
          <br />
          <input
            type="file"
            name="images"
            accept="images/*"
            onChange={handleChangeFile}
            // ref={inputRef}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Nama Produk</label>
        <input
          type="text"
          name="productName"
          className="form-control col-6"
          value={productName}
          onChange={handleChange}
          placeholder="Nama Produk"
          autoFocus
          required
        />
      </div>
      <div className="form-group">
        <label>Kategori</label>
        <select
          name="category"
          className="form-control col-3"
          value={selectedCategory ? selectedCategory : category._id}
          onChange={handleCategoryChange}
          disabled={loading}
        >
          <option>Pilih Category</option>
          {categories &&
            categories.length > 0 &&
            categories.map((c) => (
              <option key={c._id} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>
      {subShow && (
        <div className="form-group">
          <label>Sub Kategori</label>
          <br />
          <Select
            // mode="multiple"
            className="form-control col-3"
            style={{ width: "100%" }}
            placeholder="Select Sub Category"
            value={arrayOfSub && arrayOfSub}
            onChange={(value) => {
              arrayOfSub
                ? setArrayOfSub(value)
                : setValues({ ...values, subcategory: value });
            }}
          >
            {subOptions &&
              subOptions.length > 0 &&
              subOptions.map((s) => (
                <Option key={s._id} value={s._id}>
                  {s.name}
                </Option>
              ))}
          </Select>
        </div>
      )}
      <div className="form-group">
        <label>Deskripsi</label>
        <textarea
          className="form-control col-8"
          rows="10"
          name="description"
          value={description}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Jenis Kendala (Pisahkan dengan koma)</label>

        <input
          type="text"
          name="type"
          className="form-control col-3"
          value={type}
          placeholder="Problem Type"
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Unit</label>
        <select
          name="unit"
          className="form-control col-3"
          value={unit}
          onChange={handleChange}
        >
          <option>- Pilih Status -</option>
          <option value="unit">Unit</option>
          <option value="pcs">Pcs</option>
          <option value="kg">Kg</option>
        </select>
      </div>

      <div className="form-group">
        <label>Brand</label>
        <select
          name="brand"
          className="form-control col-3"
          value={brand}
          onChange={handleChange}
        >
          <option>- Pilih Brand -</option>
          {brands.map((b) => (
            <option key={b._id} value={b._id}>
              {b.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Status</label>
        <select
          name="status"
          className="form-control col-3"
          value={status}
          onChange={handleChange}
        >
          <option>- Pilih Status -</option>
          <option value="active">Aktif</option>
          <option value="inactive">Tidak Aktif</option>
        </select>
      </div>
      <br />
      <button className="btn btn-outline-primary" disabled={loading}>
        Save
      </button>
    </form>
  );
};

export default ProductForm;
