import axios from "axios";

export const getAllBpaysUser = async (authtoken, body) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/bpay-all-user`,
    body,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
        "Content-type": "application/json",
      },
    }
  );
};
