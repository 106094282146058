/* eslint-disable react/prop-types */
import React from "react";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import AdminNav from "../../components/nav/AdminNav";
import PriceListCreateForm from "./component/PriceListCreateForm";
// import { updatePriceList } from "../../functions/pricelist";

const PriceListCreate = () => {
  // const { user } = useSelector((state) => ({ ...state }));

  //   const [data, setData] = useState(location.state);
  //   const [loading, setLoading] = useState(false);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const body = JSON.stringify({
  //       content: {
  //         "content.$.name": data.name,
  //         "content.$.price": data.price,
  //         "content.$.detail": data.detail,
  //       },
  //     });
  //     // console.log(name);
  //     setLoading(true);
  //     updatePriceList(authtoken, body, match.params.id, location.state.name)
  //       .then(() => {
  //         setLoading(false);
  //         // setName("");

  //         history.goBack();
  //       })
  //       .catch((err) => {
  //         //console.log(err);
  //         setLoading(false);
  //         if (err.response.status === 400) {
  //           toast.error(err.response.data);
  //         }
  //       });
  //   };

  //   console.log("data", data);

  return (
    <div className="container-fluid">
      <>
        <h4>Create Price List</h4>

        <PriceListCreateForm
        // handleSubmit={handleSubmit}
        // data={data}
        // setData={setData}
        />
      </>
    </div>
  );
};

export default PriceListCreate;
