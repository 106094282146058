import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Layout } from "antd";
// import firebase from "firebase";

// import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import "./App.css";

import AdminRoute from "./components/routes/AdminRoute";

import Login from "./pages/auth/Login";

import AdminDashboard from "./pages/AdminDashboard";
import AdminList from "./pages/admin/AdminList";
import AdminCreate from "./pages/admin/AdminCreate";
import AdminUpdate from "./pages/admin/AdminUpdate";

import PartnerList from "./pages/partner/PartnerList";
import PartnerCreate from "./pages/partner/PartnerCreate";

import CustomerList from "./pages/customer/CustomerList";
import CustomerCreate from "./pages/customer/CustomerCreate";
import CustomerUpdate from "./pages/customer/CustomerUpdate";

import TechnicianList from "./pages/technician/TechnicianList";
import TechnicianUpdate from "./pages/technician/TechnicianUpdate";
import TechnicianDetail from "./pages/technician/TechnicianDetail";

import ListCategory from "./pages/category/ListCategory";
import CategoryUpdate from "./pages/category/CategoryUpdate";

import ListMainCategory from "./pages/maincategory/ListCategory";
import MainCategoryUpdate from "./pages/maincategory/CategoryUpdate";

import ProductList from "./pages/product/ProductList";
import ProductCreate from "./pages/product/ProductCreate";

import ListSubCategory from "./pages/subcategory/ListSubCategory";
import SubCategoryUpdate from "./pages/subcategory/SubCategoryUpdate";

import ListBank from "./pages/bank/ListBank";
import BankUpdate from "./pages/bank/BankUpdate";

import ListCity from "./pages/city/ListCity";
import CityUpdate from "./pages/city/CityUpdate";

import SkillList from "./pages/skill/SkillList";
import SkillUpdate from "./pages/skill/SkillUpdate";
import TransactionList from "./pages/transaction/TransactionList";
import TransactionUpdate from "./pages/transaction/TransactionUpdate";
import TransactionDetail from "./pages/transaction/TransactionDetail";
import PriceList from "./pages/price-list/PriceList";
import PriceListUpdate from "./pages/price-list/PriceListUpdate";
import PriceListCreate from "./pages/price-list/PriceListCreate";
import ChartTransaction from "./pages/transaction/ChartTransaction";
import BrandList from "./pages/brand/BrandList";
import BrandCreate from "./pages/brand/BrandCreate";
// const { Content } = Layout;
//const { SubMenu, Item } = Menu;

const App = () => {
  return (
    <Router>
      <Switch>
        <AdminRoute path="/" exact component={AdminDashboard} />
        <Route path="/login" exact component={Login} />
        <AdminRoute path="/dashboard" exact component={AdminDashboard} />
        <AdminRoute path="/admins" exact component={AdminList} />
        <AdminRoute path="/admin/create" exact component={AdminCreate} />
        <AdminRoute path="/admin/edit/:id" exact component={AdminUpdate} />
        <AdminRoute path="/partners" exact component={PartnerList} />
        <AdminRoute path="/partner/create" exact component={PartnerCreate} />
        <AdminRoute path="/customers" exact component={CustomerList} />
        <AdminRoute path="/customer/create" exact component={CustomerCreate} />
        <AdminRoute
          path="/customer/edit/:id"
          exact
          component={CustomerUpdate}
        />
        <AdminRoute path="/technicians" exact component={TechnicianList} />
        <AdminRoute
          path="/technician/edit/:id"
          exact
          component={TechnicianUpdate}
        />
        <AdminRoute
          path="/technician/detail/:id"
          exact
          component={TechnicianDetail}
        />
        <AdminRoute path="/maincategories" exact component={ListMainCategory} />
        <AdminRoute
          path="/maincategory/edit/:id"
          exact
          component={MainCategoryUpdate}
        />
        <AdminRoute path="/categories" exact component={ListCategory} />
        <AdminRoute
          path="/category/edit/:id"
          exact
          component={CategoryUpdate}
        />
        <AdminRoute path="/subcategories" exact component={ListSubCategory} />
        <AdminRoute
          path="/subcategory/edit/:id"
          exact
          component={SubCategoryUpdate}
        />
        <AdminRoute path="/products" exact component={ProductList} />
        <AdminRoute path="/product/create" exact component={ProductCreate} />
        <AdminRoute path="/banks" exact component={ListBank} />
        <AdminRoute path="/bank/edit/:id" exact component={BankUpdate} />
        <AdminRoute path="/cities" exact component={ListCity} />
        <AdminRoute path="/city/edit/:id" exact component={CityUpdate} />
        <AdminRoute path="/skills" exact component={SkillList} />
        <AdminRoute path="/skill/edit/:id" exact component={SkillUpdate} />
        <AdminRoute path="/transactions" exact component={TransactionList} />
        <AdminRoute path="/price-list" exact component={PriceList} />
        <AdminRoute
          path="/price-list/edit/:id"
          exact
          component={PriceListUpdate}
        />
        <AdminRoute
          path="/price-list/create"
          exact
          component={PriceListCreate}
        />
        <AdminRoute
          path="/transaction/edit/:id"
          exact
          component={TransactionUpdate}
        />
        <AdminRoute
          path="/transaction/detail/:id"
          exact
          component={TransactionDetail}
        />
        <AdminRoute
          path="/chart-transaction"
          exact
          component={ChartTransaction}
        />
        <AdminRoute path="/brands" exact component={BrandList} />
        <AdminRoute path="/brand/create" exact component={BrandCreate} />
      </Switch>
    </Router>
  );
};

export default App;
