/* eslint-disable no-undef */
import axios from "axios";

export const createTechnician = async (product, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/product`,
    product,
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const getTechniciansByCount = async (count) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/listTechnician/${count}`
  );
};
export const fetchTechniciansByFilter = async (arg) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/search/filters`,
    arg
  );
};
export const getTechnician = async (id, authtoken) => {
  // console.log("ID & TOKEN", id, authtoken);
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/engineer/detail/${id}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getRelated = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/product/related/${productId}`
  );
};
export const removeTechnician = async (id, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/engineer/delete/${id}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateTechnician = async (id, formData, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/engineer/update/${id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
        "Content-type": "multipart/form-data",
      },
    }
  );
};
export const getTechnicians = async (sort, order, page, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/engineers`,
    {
      sort,
      order,
      page,
    },
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getTechnicianTotal = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/engineerCount`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const productStar = async (productId, star, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/product/star/${productId}`,
    { star },
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const searchTechnicianByName = async (
  sort,
  order,
  page,
  search,
  authtoken
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/engineer/search/${search}`,
    {
      sort,
      order,
      page,
    },
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};

export const searchTechnicianByFilter = async (
  sort,
  order,
  page,
  id,
  city,
  status,
  authtoken
) => {
  const st = status ? `status=${status}&` : "";
  const sk = id ? `skills=${id}&` : "";
  const ct = city ? `city=${city}` : "";
  const query = st || sk || ct ? `?${sk}${st}${ct}` : "";
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/engineer/search${query}`,
    {
      sort,
      order,
      page,
    },
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
