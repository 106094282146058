/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Select } from "antd";
const { Option } = Select;

import { getSkill, getSubCategories, updateSkill } from "../../functions/skill";
import AdminNav from "../../components/nav/AdminNav";
import AdminForm from "../../components/forms/AdminForm";

const SkillUpdate = ({ history, match }) => {
  //router
  let { id } = match.params;
  // const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCate, setSelectedCate] = useState([]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  // const { name, status } = values;
  const authtoken = localStorage.getItem("token");
  console.log("IMAGE API", process.env.REACT_IMAGE_API);
  const values = { name, status, selectedCate };
  useEffect(() => {
    // setLoading(true);
    if (!values.status) {
      loadCategories();
      loadSkill();
    }
  }, [values]);
  console.log("Name", name);
  console.log("status", status);
  console.log("Cate", selectedCate);
  const loadSkill = () => {
    getSkill(id, authtoken)
      .then((res) => {
        console.log("DATA SKILL", res.data);
        setName(res.data.name);
        setStatus(res.data.status);
        setSelectedCate([res.data.subCategories]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const loadCategories = () =>
    getSubCategories().then((s) => {
      setCategories(s.data);
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    updateSkill(id, values, authtoken)
      .then((res) => {
        console.log("UPDATE", res);
        window.alert(`Skill ${res.data.data.name} updated successfully`);
        setLoading(false);
        history.push("/skills");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };
  const handleName = (e) => {
    // console.log(e.target.name, e.target.value);
    setName(e.target.value);
  };
  const handleCate = (value) => {
    setSelectedCate([...value]);
  };
  const children = [];
  categories.map((i) => {
    children.push(
      <Option key={i._id} value={i._id}>
        {i.name}
      </Option>
    );
  });
  console.log("SELECTED CATE", selectedCate);
  return (
    <div className="container-fluid">
      <h4>Edit Skills</h4>
      <hr />

      {JSON.stringify(loading)}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-4">
              <label>Name</label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={handleName}
                placeholder="Skill Name"
                autoFocus
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <label>Category</label>
            </div>
            <div className="col-8">
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%", marginTop: 10 }}
                placeholder="Please select"
                value={selectedCate}
                // defaultValue={["a10", "c12"]}
                onChange={(selectedCate) => handleCate(selectedCate)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {children}
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <label>Status</label>
            </div>
            <div className="col-8">
              <select
                className="form-control col-8"
                // onChange={(e) => setStatus(e.target.value)}
              >
                <option value="active" selected={status === "active"}>
                  Active
                </option>
                <option value="inactive" selected={status === "inactive"}>
                  Inactive
                </option>
              </select>
            </div>
          </div>
          <br />
          <button className="btn btn-outline-primary">Save</button>
        </form>
      )}
    </div>
  );
};

export default SkillUpdate;
