import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Spin, Input, Table, Button, Space } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
// import AdminNav from "../../components/nav/AdminNav";
// import ListTransaction from "../../components/lists/ListTransaction";

// import moment from "moment";
import {
  deleteContentPriceList,
  deletePriceList,
  getPriceList,
} from "../../functions/pricelist";
import { currency } from "../../functions/lib";

const PriceList = () => {
  const [pricelist, setPriceList] = useState([]);

  //   const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState<any>(10);

  const [loading, setLoading] = useState(false);
  const [activeExpRow, setActiveExpRow] = useState();
  // const [selected, setSelected] = useState();
  // const [listPrice, setListPrice] = useState({});
  // let page = 1;

  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const loadPriceList = () => {
    setLoading(true);
    getPriceList(authtoken)
      .then((res) => {
        setPriceList(
          res.data.data.map((data, index) => ({
            ...data,
            no: index + 1,
            key: data._id,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const searchProduct = () => {
    // Search Product
  };

  useEffect(() => {
    loadPriceList();
  }, []);

  const handleDelete = (name) => {
    deleteContentPriceList(authtoken, activeExpRow, name)
      .then(() => {
        setLoading(false);
        loadPriceList();
      })
      .catch((err) => console.log(err));
  };

  const handleDeletePriceList = (id) => {
    console.log("id", id);
    deletePriceList(authtoken, id);
    loadPriceList();
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Space size="middle">
          {/* <Button type="primary">Update</Button> */}
          <Button
            type="primary"
            danger
            onClick={() => handleDeletePriceList(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const expandedRowRender = () => {
    const columns = [
      {
        title: "Type Service",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "Price",
        key: "price",
        dataIndex: "price",
      },
      {
        title: "Detail",
        key: "detail",
        dataIndex: "detail",
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (text, record) => (
          <Space size="middle">
            <Link
              to={{
                pathname: `/price-list/edit/${activeExpRow}`,
                state: record,
              }}
              className="btn btn-primary btn-xs"
            >
              <EditOutlined /> Edit
            </Link>
            <a onClick={() => handleDelete(record.name)}>Delete</a>
          </Space>
        ),
      },
    ];

    let contents = [];

    pricelist.filter((data) => {
      if (data._id == activeExpRow) {
        data.content.map((data, i) =>
          contents.push({
            ...data,
            index: i,
            price: currency(data.price, "Rp "),
            detail: data.detail,
          })
        );
      }
    });

    return <Table columns={columns} dataSource={contents} pagination={false} />;
  };

  //   const changePage = (num) => {
  //     console.log("NUM", num);
  //     setPage(num);
  //   };

  return (
    <div>
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <>
          <h4>All Price List</h4>
          <hr />
          <Input
            size="large"
            placeholder="Cari Data"
            prefix={<SearchOutlined />}
            onChange={searchProduct}
          />

          <Button
            type="primary"
            style={{ marginTop: 10, marginBottom: 10, width: "10%" }}
          >
            <Link to={"/price-list/create"}>
              <text>Create</text>
            </Link>
          </Button>
          <Table
            dataSource={pricelist}
            columns={columns}
            pagination={false}
            expandable={{
              expandedRowRender,
              onExpand: (expand, record) => {
                if (expand) {
                  console.log("REC", record);
                  setActiveExpRow(record._id);
                  // setListPrice({ ...record.content });
                }
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default PriceList;
