/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { createProduct } from "../../functions/product";
import { getCategories, getCategorySubs } from "../../functions/category";
import { getBrands } from "../../functions/brand";
import AdminNav from "../../components/nav/AdminNav";
import ProductForm from "../../components/forms/ProductForm";
import FileUpload from "../../components/forms/FileUpload";

const ProductCreate = () => {
  const [values, setValues] = useState({
    productName: "",
    description: "",
    subcategory: [],
    category: [],
    images: "",
    // brands: ["Hp"],
    brand: "",
    type: "",
    unit: "",
    status: "",
  });
  const [subOptions, setSubOptions] = useState([]);
  const [subShow, setSubShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  useEffect(() => {
    loadCategories();
    loadBrands();
  }, []);
  const loadCategories = () =>
    getCategories().then((c) => {
      //console.log("GET CATEGORIES", c);
      setCategories(c.data);
    });
  const loadBrands = () =>
    getBrands().then((res) => {
      // console.log("BRANDS", res.data);
      setBrands(res.data);
    });
  const handleSubmit = (e) => {
    e.preventDefault();
    const problemType = values.type.split(",");
    const formData = new FormData();
    formData.append("productName", values.productName);
    formData.append("description", values.description);
    formData.append("category", values.category);
    formData.append("subcategory", values.subcategory);
    formData.append("brand", values.brand);
    formData.append("problemType", [...problemType]);
    formData.append("unit", values.unit);
    formData.append("status", values.status);
    // for (const d of values.images) {
    // const file = values.images;
    // const nameParts = file.name;
    // const fileType = nameParts[nameParts.length - 1];
    formData.append("images", values.images);
    // }
    console.log("FORM DATA", formData);
    createProduct(formData, authtoken)
      .then((res) => {
        //console.log(res);
        window.alert(`Product ${res.data.productName} created successfully`);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
        if (err.response.data.err.includes("duplicate")) {
          toast.error("Duplicate Product Title/Slug");
        } else {
          toast.error(err.response.data.err);
        }
      });
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    //console.log(e.target.name, "----", e.target.value);
  };
  const handleChangeFile = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };
  const handleCategoryChange = (e) => {
    e.preventDefault();
    console.log("CLICKED CATEGORY", e.target.value);
    setValues({ ...values, subcategory: [], category: e.target.value });
    getCategorySubs(e.target.value).then((res) => {
      console.log("SUB OPTIONS ON CATEGORY CLICK -->", res.data);
      setSubOptions(res.data);
    });
    setSubShow(true);
  };
  console.log("images", values.images);
  return (
    <div className="container-fluid">
      <h4>Create Product</h4>
      <hr />

      {/* <FileUpload
        values={values}
        setValues={setValues}
        setLoading={setLoading}
        loading={loading}
      /> */}

      <ProductForm
        handleChangeFile={handleChangeFile}
        values={values}
        categories={categories}
        setValues={setValues}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleCategoryChange={handleCategoryChange}
        subOptions={subOptions}
        subShow={subShow}
        brands={brands}
        setLoading={setLoading}
        loading={loading}
      />
    </div>
  );
};

export default ProductCreate;
