import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Spin, Input } from "antd";

import AdminNav from "../../components/nav/AdminNav";
import ListCustomer from "../../components/lists/ListCustomer";
import {
  removeCustomer,
  getAllCustomers,
  getCustomerTotal,
  searchCustomerByName,
} from "../../functions/customer";

const CustomerList = () => {
  const { Search } = Input;
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [customerCount, setCustomerCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    getCustomerTotal(authtoken).then((res) => {
      console.log("Customer COUNT", res.data);
      setCustomerCount(res.data);
    });
  }, []);
  const loadCustomerList = () => {
    setLoading(true);
    getAllCustomers("_id", "desc", page, authtoken)
      .then((res) => {
        setAdmins(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleRemove = (slug) => {
    let answer = window.confirm("Are you sure?");
    if (answer) {
      // console.log("send delete request", slug);
      removeCustomer(slug, authtoken)
        .then((res) => {
          loadCustomerList();
          toast.error(`Admin with name ${res.data.title} deleted successfully`);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data);
          }
          //console.log(err)
        });
    }
  };

  const customerSearch = async () => {
    if (searchText.length != 0) {
      await searchCustomerByName(searchText, authtoken)
        .then((res) => setAdmins(res.data.data))
        .catch((err) => console.log(err));
    } else {
      loadCustomerList();
      setSearchText("");
    }
  };

  useEffect(() => {
    if (searchText == "") {
      loadCustomerList();
    } else {
      customerSearch();
    }
  }, [page, searchText]);
  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>All Customers (Total : {customerCount})</h4>
      )}
      {/* <Link to="/customer/create">
            <button className="btn btn-md btn-outline-primary">
              Create Customer
            </button>
          </Link> */}
      <Search
        size="large"
        placeholder="Cari Data"
        onSearch={customerSearch}
        enterButton
        onChange={(e) => setSearchText(e.target.value)}
      />
      <hr />
      <div className="row">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Type</th>
              {/* <th>Status</th> */}
              <th>Tanggal Daftar</th>
              <th>Saldo</th>
              <th>Options</th>
            </tr>
          </thead>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          ) : (
            <tbody>
              {admins.map((admin) => (
                <ListCustomer
                  admin={admin}
                  handleRemove={handleRemove}
                  key={admin._id}
                />
              ))}
            </tbody>
          )}
        </table>
        <Pagination
          current={page}
          total={(customerCount / 10) * 5}
          onChange={(value) => setPage(value)}
        />
        {/* <p>{customerCount}</p> */}
      </div>
    </div>
  );
};

export default CustomerList;
