/* eslint-disable no-undef */
import axios from "axios";

export const getSubCategories = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/subcategories`);
};

export const getSubCategory = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/subcategory/${id}`
  );
};
export const removeSubCategory = async (id, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/subcategory/delete/${id}`,

    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateSubCategory = async (id, category, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/subcategory/update/${id}`,
    category,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};

export const createSubCategory = async (category, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/subcategory/create`,
    category,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};

export const getSubCategorySubs = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/category/subcategory/${_id}`
  );
};
