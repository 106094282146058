import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Pagination,
  Spin,
  Input,
  Table,
  Space,
  Button,
  Dropdown,
  Menu,
  DatePicker,
  Image,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AdminNav from "../../components/nav/AdminNav";
import ListTransaction from "../../components/lists/ListTransaction";
import {
  getTransactions,
  // removeTransaction,
  getTransactionTotal,
  searchTransaction,
} from "../../functions/transaction";

import moment from "moment";
import { currency } from "../../functions/lib";
const TransactionList = () => {
  const { Search } = Input;
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState<any>(10);
  const [transactionCount, setTransactionCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeExpRow, setActiveExpRow] = useState();
  const [filter, setFilter] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showPaginate, setShowPaginate] = useState(true);

  const { RangePicker } = DatePicker;
  // let page = 1;

  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    getTransactionTotal(authtoken).then((res) => {
      setTransactionCount(res.data);
    });
  }, []);
  const loadTransactionList = () => {
    setLoading(true);
    getTransactions(page, filter, authtoken, startDate, endDate)
      .then((res) => {
        console.log(res.data.data);
        setOrders([
          ...res.data.data.map((data, index) => ({
            ...data,
            key: data._id,
            no: index + 1,
            customer_name: data.customer.fullName || "-",
            customer_phone: data?.customer?.phone || "-",
            technician_name: data?.technician?.fullName || "-",
            technician_phone: data?.technician?.mainPhone || "-",
            city: data?.address?.coordinates?.match?.adm_level_4?.name || "-",

            payment: !data.payment ? "service_b2b" : data?.payment?.type,
            total: !data.total_price
              ? currency(0, "Rp ")
              : currency(data.total_price, "Rp "),
            payment_price: !data.payment
              ? "-"
              : data?.type == "service" || data?.type == "chat"
              ? data?.payment?.type == "bpay"
                ? currency(0, "Rp ")
                : data?.payment?.type == "gopay"
                ? currency(
                    ((data?.total_price -
                      data?.detail[0]?.pricing?.payment_price) *
                      2.2) /
                      100,
                    "Rp "
                  )
                : data?.payment?.type == "qris"
                ? currency(
                    ((data?.total_price -
                      data?.detail[0]?.pricing?.payment_price) *
                      0.77) /
                      100,
                    "Rp "
                  )
                : data?.payment?.type == "bank_transfer"
                ? currency(4400, "Rp ")
                : 0
              : data?.payment.type == "gopay"
              ? currency(
                  ~~(
                    ((data?.total_price - data.detail[0].payment_price) * 2.2) /
                    100
                  ),
                  "Rp "
                )
              : data.payment.type == "qris"
              ? currency(
                  ~~(
                    ((data.total_price - data?.detail[0]?.payment_price) *
                      0.77) /
                    100
                  ),
                  "Rp "
                )
              : data.payment.type == "bank_transfer"
              ? currency(data?.detail[0]?.payment_price - 600, "Rp ")
              : currency(0, "Rp "),

            difference_midtrans_payin: !data.payment
              ? "-"
              : data.type == "service" || data.type == "chat"
              ? data.payment.type == "bpay"
                ? currency(0, "Rp ")
                : data.payment.type == "gopay"
                ? currency(
                    data?.detail[0]?.pricing?.payment_price -
                      ((data?.total_price -
                        data?.detail[0]?.pricing?.payment_price) *
                        2.2) /
                        100,
                    "Rp "
                  )
                : data?.payment?.type == "qris"
                ? currency(
                    data?.detail[0]?.pricing?.payment_price -
                      ((data?.total_price -
                        data?.detail[0]?.pricing?.payment_price) *
                        0.77) /
                        100,
                    "Rp "
                  )
                : data.payment.type == "bank_transfer"
                ? 600
                : 0
              : data.payment.type == "gopay"
              ? currency(
                  ~~(
                    data?.detail[0]?.payment_price -
                    ((data?.total_price - data?.detail[0]?.payment_price) *
                      2.2) /
                      100
                  ),
                  "Rp "
                )
              : data.payment.type == "qris"
              ? currency(
                  data?.detail[0]?.payment_price -
                    ((data.total_price - data?.detail[0]?.payment_price) *
                      0.77) /
                      100,
                  "Rp "
                )
              : data.payment.type == "bank_transfer"
              ? currency(data.detail[0].payment_price - 4400, "Rp ")
              : currency(0, "Rp "),
            admin_price:
              currency(data?.detail[0]?.pricing?.admin_price, "Rp ") ||
              currency(data?.detail[0]?.admin_price, "Rp "),
            app_price:
              data.type == "service"
                ? currency(
                    ((data?.detail[0]?.pricing?.service_price +
                      data?.detail[0]?.pricing?.distance_price) *
                      10) /
                      100,
                    "Rp "
                  )
                : data.type == "chat"
                ? currency(
                    (data?.detail[0]?.pricing?.service_price * 10) / 100,
                    "Rp "
                  )
                : currency(0, "Rp "),
            service_price:
              currency(data?.detail[0]?.pricing?.service_price, "Rp ") || "-",
            distance_price:
              currency(data?.detail[0]?.pricing?.distance_price, "Rp ") || "-",
            created_at: moment(data.created_at).format("DD/MM/YYYY"),
            imageBefore: data?.beforeWorkPhotos,
            imageAfter: data?.afterWorkPhotos,
          })),
        ]);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // const handleRemove = (slug) => {
  //   let answer = window.confirm("Are you sure?");
  //   if (answer) {
  //     // console.log("send delete request", slug);
  //     removeTransaction(slug, authtoken)
  //       .then((res) => {
  //         loadTransactionList();
  //         toast.error(
  //           `Order with name ${res.data.data.fullName} deleted successfully`
  //         );
  //       })
  //       .catch((err) => {
  //         // if (err.response.status === 400) {
  //         //   toast.error(err.response.data);
  //         // }
  //         console.log(err);
  //       });
  //   }
  // };
  const search = (value) => {
    // Search Product
    console.log(value.length);
    if (value.length != 0) {
      searchTransaction(authtoken, value).then((res) => {
        setOrders(
          res.data.data.map((data, index) => ({
            ...data,
            key: data._id,
            no: index + 1,
            customer_name: data.customer.fullName || "-",
            customer_phone: data?.customer?.phone || "-",
            technician_name: data?.technician?.fullName || "-",
            technician_phone: data?.technician?.mainPhone || "-",
            city: data?.address?.coordinates?.match?.adm_level_4?.name || "-",
            payment: data.payment.type,
            payment_price:
              data.type == "service" || data.type == "chat"
                ? data?.payment?.type == "bpay"
                  ? 0
                  : data?.payment?.type == "gopay"
                  ? ((data?.total_price -
                      data?.detail[0]?.pricing?.payment_price) *
                      2.2) /
                    100
                  : data?.payment?.type == "qris"
                  ? ((data?.total_price -
                      data?.detail[0]?.pricing?.payment_price) *
                      0.77) /
                    100
                  : data?.payment?.type == "bank_transfer"
                  ? 4400
                  : 0
                : data.payment.type == "gopay"
                ? ~~(
                    ((data?.total_price - data.detail[0].payment_price) * 2.2) /
                    100
                  )
                : data.payment.type == "qris"
                ? ~~(
                    ((data.total_price - data?.detail[0]?.payment_price) *
                      0.77) /
                    100
                  )
                : data.payment.type == "bank_transfer"
                ? data?.detail[0]?.payment_price - 600
                : 0,

            difference_midtrans_payin:
              data.type == "service" || data.type == "chat"
                ? data.payment.type == "bpay"
                  ? 0
                  : data.payment.type == "gopay"
                  ? data?.detail[0]?.pricing?.payment_price -
                    ((data?.total_price -
                      data?.detail[0]?.pricing?.payment_price) *
                      2.2) /
                      100
                  : data?.payment?.type == "qris"
                  ? data?.detail[0]?.pricing?.payment_price -
                    ((data?.total_price -
                      data?.detail[0]?.pricing?.payment_price) *
                      0.77) /
                      100
                  : data.payment.type == "bank_transfer"
                  ? 600
                  : 0
                : data.payment.type == "gopay"
                ? ~~(
                    data?.detail[0]?.payment_price -
                    ((data?.total_price - data?.detail[0]?.payment_price) *
                      2.2) /
                      100
                  )
                : data.payment.type == "qris"
                ? data?.detail[0]?.payment_price -
                  ((data.total_price - data?.detail[0]?.payment_price) * 0.77) /
                    100
                : data.payment.type == "bank_transfer"
                ? data.detail[0].payment_price - 4400
                : 0,
            admin_price:
              data?.detail[0]?.pricing?.admin_price ||
              data?.detail[0]?.admin_price,
            app_price:
              data.type == "service"
                ? ((data?.detail[0]?.pricing?.service_price +
                    data?.detail[0]?.pricing?.distance_price) *
                    10) /
                  100
                : data.type == "chat"
                ? (data?.detail[0]?.pricing?.service_price * 10) / 100
                : 0,
            service_price: data?.detail[0]?.pricing?.service_price || "-",
            distance_price: data?.detail[0]?.pricing?.distance_price || "-",
            created_at: moment(data.created_at).format("DD/MM/YYYY"),
          }))
        );

        setLoading(false);
      });
      setShowPaginate(false);
    }
  };

  useEffect(() => {
    if (page) {
      loadTransactionList();
    }
  }, [page, filter, startDate, endDate]);

  const changePage = (num) => {
    setPage(num);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Customer Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
    },
    {
      title: "Technician Name",
      dataIndex: "technician_name",
      key: "technician_name",
    },
    {
      title: "Technician Phone",
      dataIndex: "technician_phone",
      key: "technician_phone",
    },
    {
      title: "Order Site",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Midtrans Pay in",
      dataIndex: "payment_price",
      key: "payment_price",
    },
    {
      title: "Selisih Midtrans Pay in",
      dataIndex: "difference_midtrans_payin",
      key: "difference_midtrans_payin",
    },
    {
      title: "Biaya Admin",
      dataIndex: "admin_price",
      key: "admin_price",
    },
    {
      title: "Biaya Jasa",
      dataIndex: "service_price",
      key: "service_price",
    },
    {
      title: "Biaya Jarak",
      dataIndex: "distance_price",
      key: "distance_price",
    },
    {
      title: "Biaya Aplikasi 10%",
      dataIndex: "app_price",
      key: "app_price",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Payment Method",
      dataIndex: "payment",
      key: "payment",
    },

    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },

    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: () => <Button>Update</Button>,
    },
  ];

  const handleMenuClick = ({ key }) => {
    console.log("aaaa", key);
    setFilter(key);
  };

  const handleDate = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    console.log("date : ", date[0]._d);
    console.log("date string", dateString);
  };

  console.log("fil", filter);
  console.log("start", startDate);
  console.log("end", endDate);
  // console.log("orders", orders);
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="all">All</Menu.Item>
      <Menu.Item key="topup">Top Up</Menu.Item>
      <Menu.Item key="service">Service</Menu.Item>
      <Menu.Item key="chat">Chat</Menu.Item>
      <Menu.Item key="product">Product</Menu.Item>
      <Menu.Item key="subscription">Subscription</Menu.Item>
    </Menu>
  );

  return (
    <div>
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>All Transactions ({transactionCount})</h4>
      )}
      {/* <Link to="/partner/create">
            <button className="btn btn-md btn-outline-primary">
              Create Transaction
            </button>
          </Link> */}
      <hr />
      <Search
        placeholder="input search text"
        onSearch={search}
        enterButton
        style={{ marginBottom: 10 }}
      />
      <div style={{ flexDirection: "row", display: "flex" }}>
        <Dropdown overlay={menu} placement="bottomLeft" arrow>
          <Button onClick={(e) => e.preventDefault()}>Filter</Button>
        </Dropdown>
        <span style={{ marginRight: 20 }} />
        <RangePicker onChange={handleDate} />
      </div>

      <Table
        style={{ marginTop: 10 }}
        dataSource={orders}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ flexDirection: "row", display: "flex" }}>
              <ul>
                <li>Tipe Servis : {record?.detail[0]?.problem?.name || "-"}</li>
                <li>
                  Permasalahan : {record?.detail[0]?.problem_detail || "-"}
                </li>
                <li>
                  Brand Perangkat / Kendaraan :{" "}
                  {record?.detail[0]?.brand?.name || "-"}
                </li>
                <li>
                  Tipe Perangkat / Kendaraan :{" "}
                  {record?.detail[0]?.type?.name || "-"}
                </li>
                <li>
                  Jadwal Service : {record?.time?.date?.formatted || "-"}, pukul{" "}
                  {record?.time?.time || "-"}
                </li>
                <li>
                  Alamat :{" "}
                  {record?.address?.position ||
                    record?.address?.coordinates?.match?.display_txt ||
                    record?.address?.company_address}
                </li>
                <li>Perusahaan : {record?.address?.company || "-"}</li>
              </ul>
              <div style={{ marginLeft: 40 }}>
                <p>Foto Sebelum Bekerja:</p>
                <br />
                <Image
                  width={200}
                  src={`${process.env.REACT_APP_IMAGE_API}/services/${record.imageBefore}`}
                />
              </div>
              <div style={{ marginLeft: 40 }}>
                <p>Foto Sebelum Bekerja:</p>
                <br />
                <Image
                  width={200}
                  src={`${process.env.REACT_APP_IMAGE_API}/services/${record.imageAfter}`}
                />
              </div>
            </div>
          ),

          expandedRowKeys: activeExpRow,
          onExpand: (expanded, record) => {
            console.log("rec", record);
            const keys = [];
            if (expanded) {
              keys.push(record._id);
            }
            console.log("key", keys);
            setActiveExpRow(keys);
          },
        }}
      />
      {showPaginate && (
        <Pagination
          current={page}
          total={(transactionCount / 10) * 5}
          onChange={changePage}
        />
      )}
    </div>
  );
};

export default TransactionList;
