import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import moment from "moment";
import { chartTransaction, getBruto } from "../../functions/transaction";
import { currency } from "../../functions/lib";

const ChartTransaction = () => {
  const authtoken = localStorage.getItem("token");
  const [transaction, setTransaction] = useState([]);
  const [bruto, setBruto] = useState(0);
  const getData = () => {
    chartTransaction(authtoken).then((res) => {
      setTransaction(
        res.data
          .filter(
            (d, i, a) =>
              a
                .map((d) => new Date(d.created_at).getMonth())
                .indexOf(new Date(d.created_at).getMonth()) == i
          )
          .map((data) => ({
            ...data,
            month: moment(data.created_at).format("MMMM"),
          }))
      );
    });
    getBruto(authtoken).then((res) => {
      console.log(res);
      setBruto(res?.data[0]?.bruto);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <h4>Diagram</h4>
      <LineChart
        width={600}
        height={300}
        data={transaction}
        margin={{ top: 5, right: 20, bottom: 5, left: 10 }}
      >
        <Line type="monotone" dataKey="total_price" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
      </LineChart>
      <br />
      <h4>Bruto : {currency(Math.ceil(bruto), "Rp ")}</h4>
    </div>
  );
};

export default ChartTransaction;
