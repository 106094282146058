import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;
const d = new Date();
let year = d.getFullYear();
const Foots = () => {
  return (
    <Layout>
      <Footer
        style={{
          textAlign: "center",
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        &copy; {year} Service Hub
      </Footer>
    </Layout>
  );
};

export default Foots;
