/* eslint-disable no-undef */
import axios from "axios";

export const getCities = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/city/list`);
};
export const getCity = async (id) => {
  return await axios.get(`${process.env.REACT_APP_API}/city/${id}`);
};
export const removeCity = async (slug, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/city/${slug}`,

    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateCity = async (id, city, authtoken) => {
  return await axios.put(`${process.env.REACT_APP_API}/city/${id}`, city, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};

export const createCity = async (city, authtoken) => {
  console.log("BANK", city);
  return await axios.post(`${process.env.REACT_APP_API}/city/create`, city, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};

export const getCitySubs = async (_id) => {
  return await axios.get(`${process.env.REACT_APP_API}/city/subcity/${_id}`);
};
