/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { getTechnician, updateTechnician } from "../../functions/technician";
import AdminNav from "../../components/nav/AdminNav";
// import AdminForm from "../../components/forms/AdminForm";
import { REACT_IMAGE_API } from "../../config/env";
import { Spin } from "antd";

const TechnicianDetail = ({ history, match }) => {
  let { id } = match.params;
  const [values, setValues] = useState([]);
  const [bank, setBank] = useState();
  const [loading, setLoading] = useState(false);
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  const {
    fullName,
    email,
    secondPhone,
    mainPhone,
    city,
    skills,
    bankDetails,
    idCard,
    address,
    status,
  } = values;
  useEffect(() => {
    setLoading(true);
    loadData();
  }, []);

  const loadData = () => {
    getTechnician(id, authtoken)
      .then((res) => {
        setValues([res.data.technician]);
        console.log("BANK", res.data.bank);
        setBank(res.data.bank);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const handleAccept = (id) => {
    // Accept
    const type = "active";
    updateTechnician(id, { type: type }, authtoken).then((res) => {
      history.push("/technicians");
    });
  };
  const handleReject = (id) => {
    // Reject
    const type = "reject";
    updateTechnician(id, { type: type }, authtoken).then((res) => {
      history.push("/technicians");
    });
  };
  return (
    <div className="container-fluid">
      <h4>Detail Technician</h4>
      <hr />
      {/* {JSON.stringify(bank)} */}
      {values.map((d, i) => (
        <>
          <div className="row" key={i}>
            <div className="col-md-4">
              <img
                src={`${REACT_IMAGE_API}/${d.profilePicture}`}
                style={{ width: 300, height: 300 }}
              />
            </div>
            <div className="col-md-8">
              <div className="row mb-3">
                <div className="col-md-3">Name</div>
                <div className="col-md-9">: {d.fullName}</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">Email</div>
                <div className="col-md-9">: {d.email}</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">Main Phone</div>
                <div className="col-md-9">: {d.mainPhone}</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">Second Phone</div>
                <div className="col-md-9">: {d.secondPhone}</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">City</div>
                <div className="col-md-9">: {d.city.name}</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">Skills</div>
                <div className="col-md-9">
                  :{" "}
                  {d.skills.map((d, index) => (
                    <>
                      {d.name}
                      <br />
                    </>
                  ))}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">Bank Details</div>
                <div className="col-md-9">
                  : <br />
                  {bank &&
                    bank.detail.map((a, b) => (
                      <>
                        <>Nama: {a.name}</>
                        <br />
                        <>No. Rek: {a.no}</>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              KTP
              <br />
              <img
                src={`${REACT_IMAGE_API}/${d.idCard}`}
                style={{ width: 300, height: 300 }}
              />
            </div>
            <div className="col-md-6">
              {/* Vehicle Registration */}
              <br />
              {/* <img
                src={`${process.env.REACT_IMAGE_API}/${d.vehicleRegist}`}
                style={{ width: 300, height: 300 }}
              /> */}
            </div>
          </div>
          {d.status !== "active" && (
            <div className="row">
              <div className="col-md-12">
                <hr />
                <button
                  className="btn btn-outline-primary btn-lg"
                  onClick={() => handleAccept(d._id)}
                >
                  Accept
                </button>
                <button
                  className="btn btn-outline-danger btn-lg"
                  onClick={() => handleReject(d._id)}
                >
                  Reject
                </button>
                <hr />
              </div>
            </div>
          )}
          <br />
        </>
      ))}
    </div>
  );
};

export default TechnicianDetail;
