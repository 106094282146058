/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

import { updateCategory, getCategory } from "../../functions/category";
import AdminNav from "../../components/nav/AdminNav";
import CategoryForm from "../../components/forms/CategoryForm";

const CategoryUpdate = ({ history, match }) => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadCategory = () =>
      getCategory(match.params.id, authtoken).then((c) => setName(c.data.name));
    loadCategory();
  }, [match.params.slug]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    updateCategory(match.params.id, { name }, authtoken)
      .then((res) => {
        setLoading(false);
        setName("");
        toast.success(
          `Category with name "${res.data.name}" updated successfully`
        );
        history.push("/admin/category");
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
      });
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>Update Category</h4>
      )}
      <CategoryForm handleSubmit={handleSubmit} name={name} setName={setName} />
    </div>
  );
};

export default CategoryUpdate;
