import axios from "axios";

export const createOrUpdateUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-or-update-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const loginWithEmail = async (email, password) => {
  console.log(email, password);
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/login`,
    { email, password },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const currentUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/current-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentAdmin = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/check-admin`,
    {},
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
