/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAllBpaysUser } from "../../functions/bpay";
import { currency } from "../../functions/lib";

// import defaultImage from "../../images/default.png";

const ListCustomer = ({ admin, handleRemove }) => {
  const { _id, fullName, email, phone, type, created_at, address } = admin;
  const [balance, setBalance] = useState(0);
  const authtoken = localStorage.getItem("token");
  const getBpays = () => {
    let body = JSON.stringify({
      role: "customer",
      account_id: _id,
    });
    getAllBpaysUser(authtoken, body).then((res) => {
      console.log(res.data.balance);
      setBalance(res.data.balance);
    });
  };

  console.log("add", address);
  useEffect(() => {
    getBpays();
  }, []);
  return (
    <tr>
      <td>
        <input type="checkbox" name="" id="" />
      </td>
      <td>{fullName}</td>
      <td>{phone}</td>
      <td>{email}</td>
      <td>{address[0]?.coordinates?.match?.adm_level_4?.name || "-"}</td>
      <td>{type}</td>
      {/* <td>{status}</td> */}
      <td>{moment(created_at).format("DD MMMM YYYY")}</td>
      <td>{currency(balance, "Rp ") || "Rp 0"}</td>
      <td>
        <Link to={`/customer/edit/${_id}`}>
          <EditOutlined className="btn btn-outline-primary mr-3" />
        </Link>{" "}
        <DeleteOutlined
          onClick={() => handleRemove(_id)}
          className="btn btn-outline-danger"
        />
      </td>
    </tr>
  );
};

export default ListCustomer;
