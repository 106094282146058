import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";

import { createBrand, getBrands, removeBrand } from "../../functions/brand";
import AdminNav from "../../components/nav/AdminNav";
import BrandForm from "../../components/forms/BrandForm";
import LocalSearch from "../../components/forms/LocalSearch";

const ListBrand = () => {
  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [brandId, setBrandId] = useState("");
  const [detail, setDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [status, setStatus] = useState("");

  // searching & filtering
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    loadBrands();
  }, []);
  const loadBrands = () => getBrands().then((c) => setBrands(c.data));

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    const status = "active";
    const brandData = { name, code, detail, status };
    createBrand(brandData, authtoken)
      .then((res) => {
        setLoading(false);
        setName("");
        setCode("");
        setDetail("");
        toast.success(
          `Brand with name "${res.data.data.name}" created successfully`
        );
        setIsModalVisible(false);
        loadBrands();
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          toast.error(err.response.data);
        }
      });
  };
  const handleRemove = async (slug) => {
    if (window.confirm("Are You Sure?")) {
      setLoading(true);
      removeBrand(slug, authtoken)
        .then((res) => {
          setLoading(false);
          toast.success(`Success Delete "${res.data.name}" Brand`);
          loadBrands();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setLoading(false);
            toast.error(err.response.data);
          }
        });
    }
  };
  // handle change

  const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>List Brand</h4>
      )}

      <Button type="primary" onClick={showModal}>
        <PlusOutlined /> Create Brand
      </Button>
      <Modal
        title="Create Brand"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <BrandForm
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
          code={code}
          setCode={setCode}
          brandId={brandId}
          setBrandId={setBrandId}
          detail={detail}
          status={status}
          setStatus={setStatus}
          setDetail={setDetail}
        />
      </Modal>
      <hr />
      <LocalSearch setKeyword={setKeyword} keyword={keyword} />
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Detail</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {brands.filter(searched(keyword)).map((c) => (
            <tr key={c._id}>
              <td>{c.brandId}</td>
              <td> {c.name}</td>
              <td>{c.brandDetail}</td>
              <td>
                {c.status === "active" ? (
                  <span
                    style={{
                      backgroundColor: "green",
                      padding: 5,
                      color: "#fff",
                      borderRadius: 5,
                    }}
                  >
                    Active
                  </span>
                ) : (
                  <span
                    style={{
                      backgroundColor: "gray",
                      padding: 5,
                      color: "#fff",
                      borderRadius: 5,
                    }}
                  >
                    Inactive
                  </span>
                )}
              </td>
              <td>
                <Link
                  to={`/brand/edit/${c._id}`}
                  className="btn btn-primary btn-xs"
                >
                  <EditOutlined /> Edit
                </Link>
                |
                <span
                  onClick={() => handleRemove(c.slug)}
                  className="btn btn-danger btn-xs"
                >
                  <DeleteOutlined /> Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListBrand;
