/* eslint-disable react/prop-types */
import React from "react";

const BrandForm = ({
  handleSubmit,
  name,
  setName,
  code,
  setCode,
  brandId,
  setBrandId,
  detail,
  setDetail,
  status,
  setStatus,
}) => {
  console.log(brandId, setBrandId);
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4">
          <label>Name</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Brand Name"
            autoFocus
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Brand Code</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Brand Code"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Brand Detail</label>
        </div>
        <div className="col-8">
          <input
            type="text"
            className="form-control"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
            placeholder="Brand Detail"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Status</label>
        </div>
        <div className="col-8">
          <select
            className="form-control col-8"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active" selected={status === "active"}>
              Active
            </option>
            <option value="inactive" selected={status === "inactive"}>
              Inactive
            </option>
          </select>
        </div>
      </div>
      <br />
      <button className="btn btn-outline-primary">Save</button>
    </form>
  );
};

export default BrandForm;
