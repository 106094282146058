import React from "react";
import { Link } from "react-router-dom";
import { Menu, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  AlignLeftOutlined,
  UserOutlined,
  InsertRowBelowOutlined,
  BankOutlined,
  FullscreenExitOutlined,
  GiftOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  LineChartOutlined,
  DollarOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

const AdminNav = () => {
  return (
    <div>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
      >
        <Menu.Item key="sub1" icon={<PieChartOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <SubMenu key="sub2" icon={<UserOutlined />} title="Users">
          <Menu.Item key="3">
            <Link to="/admins">List Admin</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/partners">List Partner</Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/customers">List Customer</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/technicians">List Technician</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<LineChartOutlined />} title="Transactions">
          <Menu.Item key="7">
            <Link to="/transactions">List Transaction</Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link to="/chart-transaction">Chart Transaction</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub4" icon={<InsertRowBelowOutlined />} title="Brands">
          <Menu.Item key="9">
            <Link to="/brands">List Brand</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub5" icon={<GlobalOutlined />} title="City">
          <Menu.Item key="10">
            <Link to="/cities">List City</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub6" icon={<AlignLeftOutlined />} title="Categories">
          {/* <Menu.Item key="7">
            <Link to="/maincategories">List Main Category</Link>
          </Menu.Item> */}
          <Menu.Item key="11">
            <Link to="/categories">List Category</Link>
          </Menu.Item>
          <Menu.Item key="12">
            <Link to="/subcategories">List SubCategory</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub7" icon={<InsertRowBelowOutlined />} title="Products">
          <Menu.Item key="13">
            <Link to="/products">List Product</Link>
          </Menu.Item>
        </SubMenu>
        {/* <SubMenu key="sub6" icon={<BankOutlined />} title="Banks">
          <Menu.Item key="12">
            <Link to="/banks">List Bank</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub6" icon={<FullscreenExitOutlined />} title="Skills">
          <Menu.Item key="12">
            <Link to="/skills">List Skill</Link>
          </Menu.Item>
        </SubMenu> */}
        {/* <SubMenu key="sub7" icon={<ShoppingCartOutlined />} title="Orders">
          <Menu.Item key="13">
            <Link to="/skills">List Order</Link>
          </Menu.Item>
        </SubMenu> */}

        <SubMenu key="sub8" icon={<GiftOutlined />} title="Marketing">
          <Menu.Item key="14">
            <Link to="/">User Icon Menu</Link>
          </Menu.Item>
          <Menu.Item key="15">
            <Link to="/">User Slider</Link>
          </Menu.Item>
          <Menu.Item key="16">
            <Link to="/">Coupons</Link>
          </Menu.Item>
          <Menu.Item key="17">
            <Link to="/">Promos</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub9"
          icon={<DollarOutlined />}
          title="Price List Service"
        >
          <Menu.Item key="18">
            <Link to="/price-list">Price List</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub10" icon={<SettingOutlined />} title="App Setting">
          <Menu.Item key="19">
            <Link to="/">Versioning</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
  // <nav>
  //   <ul className='nav flex-column'>
  //     <li className='nav-item'>
  //       <Link to='/dashboard' className='nav-link'>
  //         Dashboard
  //       </Link>
  //     </li>
  //     <li className='nav-item'>
  //       <Link to='/product' className='nav-link'>
  //         Product
  //       </Link>
  //     </li>
  //     <li className='nav-item'>
  //       <Link to='/products' className='nav-link'>
  //         Products
  //       </Link>
  //     </li>
  //     <li className='nav-item'>
  //       <Link to='/category' className='nav-link'>
  //         Category
  //       </Link>
  //     </li>
  //     <li className='nav-item'>
  //       <Link to='/subcategory' className='nav-link'>
  //         Sub Category
  //       </Link>
  //     </li>
  //     <li className='nav-item'>
  //       <Link to='/coupons' className='nav-link'>
  //         Coupons
  //       </Link>
  //     </li>
  //     <li className='nav-item'>
  //       <Link to='/user/password' className='nav-link'>
  //         Password
  //       </Link>
  //     </li>
  //   </ul>
  // </nav>
};

export default AdminNav;
