/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Select } from "antd";
import { getSubCategories } from "../../../functions/subcategory";
import { createPriceList } from "../../../functions/pricelist";

const PriceListCreateForm = () => {
  const { Option } = Select;
  const history = useHistory();
  const authtoken = localStorage.getItem("token");
  const [subcategories, setSubCategories] = useState([]);

  const [content, setContent] = useState([
    {
      name: "",
      price: 0,
      detail: "",
    },
  ]);

  const [data, setData] = useState({
    name: "",
    content: [],
    subcategory: "",
  });
  const loadSubCategory = () => {
    getSubCategories().then((res) => setSubCategories(res.data));
  };

  useEffect(() => {
    loadSubCategory();
  }, []);

  const handleAddContent = () => {
    setContent([
      ...content,
      {
        name: "",
        price: "",
        detail: "",
      },
    ]);
  };

  const handleRemoveContent = (i) => {
    const list = [...content];
    list.splice(i, 1);
    setContent(list);
  };

  const handleContentChange = (e, i) => {
    const { name, value } = e.target;
    // console.log(name)
    const list = content;
    list[i][name] = value;
    setContent([...list]);
  };

  const handleSelectSubcategory = (value) => {
    setData({
      ...data,
      subcategory: value,
    });
  };

  useEffect(() => {
    if (content.length != 0) {
      setData({
        ...data,
        content: [...content],
      });
    }
  }, [content]);

  const handleSubmit = () => {
    const body = JSON.stringify({
      ...data,
    });
    console.log("80");
    createPriceList(authtoken, body);
    history.push("/price-list");
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Name Price List</label>
      <input
        type="text"
        className="form-control col-6"
        value={data.name}
        onChange={(e) =>
          setData({
            ...data,
            name: e.target.value,
          })
        }
        placeholder="Name Price List"
        autoFocus
        required
      />
      <br />
      <label>Pilih Subcategory</label>
      <br />
      <Select
        style={{ width: 300 }}
        placeholder="Pilih Sub Kategori"
        onChange={handleSelectSubcategory}
      >
        {subcategories.map((d, i) => (
          <Option value={d._id} key={i}>
            {d.name}
          </Option>
        ))}
      </Select>
      <br />
      <br />
      <div>
        <h4>Jenis Servis</h4>
        {content.map((contents, i) => (
          <div key={i} style={{ marginBottom: 20 }}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ width: 500 }}>
                <label>Type Service</label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  value={contents.name}
                  onChange={(e) => handleContentChange(e, i)}
                  placeholder="CTH: Servis Ringan, Servis Sedang ,Servis Berat"
                  autoFocus
                  required
                />
                <br />
                <label>Price</label>
                <input
                  name="price"
                  type="number"
                  className="form-control"
                  value={contents.price}
                  onChange={(e) => handleContentChange(e, i)}
                  placeholder="Price"
                  autoFocus
                  required
                />
                <br />
                <label>Detail</label>
                <input
                  name="detail"
                  type="text"
                  className="form-control"
                  value={contents.detail}
                  onChange={(e) => handleContentChange(e, i)}
                  placeholder="Detail"
                  autoFocus
                  required
                />
              </div>
              {content.length > 1 && (
                <Button
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    marginLeft: 20,
                  }}
                  type="primaty"
                  danger
                  onClick={() => handleRemoveContent(i)}
                >
                  <text>Hapus</text>
                </Button>
              )}
            </div>
            {content.length - 1 == i && content.length < 4 && (
              <Button
                style={{ justifyContent: "flex-start", marginTop: 20 }}
                type="primary"
                onClick={handleAddContent}
              >
                Tambah Jenis Servis
              </Button>
            )}
          </div>
        ))}
      </div>
      <br />
      <button
        // type="submit"
        // onClick={() => handleSubmit()}
        className="btn btn-outline-primary"
      >
        Save
      </button>{" "}
      <Link to={`/price-list`} className="btn btn-outline-danger btn-xs">
        Cancel
      </Link>
      {/* <button className="btn btn-outline-danger">Cancle</button> */}
    </form>
  );
};

export default PriceListCreateForm;
