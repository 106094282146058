/* eslint-disable react/prop-types */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
// import Moment from "moment";
import { Link } from "react-router-dom";
import { getAllBpaysUser } from "../../functions/bpay";
import { currency } from "../../functions/lib";

// import defaultImage from "../../images/default.png";

const ListPartner = ({ admin, handleRemove }) => {
  const { _id, storeName, email, phone, status, addressStore } = admin;
  const [balance, setBalance] = useState(0);
  const authtoken = localStorage.getItem("token");
  const getBpays = () => {
    let body = JSON.stringify({
      role: "partner",
      account_id: _id,
    });
    getAllBpaysUser(authtoken, body).then((res) => {
      console.log(res.data.balance);
      setBalance(res.data.balance);
    });
  };

  useEffect(() => {
    getBpays();
  }, []);
  // console.log("shoe=w partner", admin);
  return (
    <tr>
      <td>
        <input type="checkbox" name="" id="" />
      </td>
      <td>{storeName}</td>
      <td>{phone}</td>
      <td>{email}</td>
      <td>{addressStore}</td>
      <td>{status}</td>
      <td>{currency(balance, "Rp ") || "Rp 0"}</td>
      <td>
        <Link to={`/admins/edit/${_id}`}>
          <EditOutlined className="btn btn-outline-primary mr-3" />
        </Link>{" "}
        <DeleteOutlined
          onClick={() => handleRemove(_id)}
          className="btn btn-outline-danger"
        />
      </td>
    </tr>
  );
};

export default ListPartner;
