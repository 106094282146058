import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from "antd";

import AdminNav from "../../components/nav/AdminNav";
import ListAdmin from "../../components/lists/ListAdmin";
import {
  getAllAdmins,
  removeAdmin,
  getAdminTotal,
} from "../../functions/admin";

const AdminList = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adminCount, setAdminCount] = useState(0);
  const [page, setPage] = useState(1);

  // const { user } = useSelector((state) => ({ ...state }));
  const authtoken = localStorage.getItem("token");
  useEffect(() => {
    loadAdminList();
  }, [page]);
  useEffect(() => {
    getAdminTotal(authtoken).then((res) => {
      console.log("ADMIN COUNT", res.data);
      setAdminCount(res.data);
    });
  }, []);

  // console.log("USER ON ADMIN LIST", user);
  const loadAdminList = () => {
    setLoading(true);
    getAllAdmins("_id", "desc", page, authtoken)
      .then((res) => {
        setAdmins(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleRemove = (id) => {
    let answer = window.confirm("Are you sure?");
    if (answer) {
      // console.log("send delete request", slug);
      removeAdmin(id, authtoken)
        .then((res) => {
          loadAdminList();
          toast.error(
            `Admin with name ${res.data.data.username} deleted successfully`
          );
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data);
          }
          //console.log(err)
        });
    }
  };
  return (
    <div className="container-fluid">
      {loading ? (
        <h4 className="text-danger">Loading...</h4>
      ) : (
        <h4>All Admins</h4>
      )}
      <Link to="/admin/create">
        <button className="btn btn-md btn-outline-primary">Create Admin</button>
      </Link>

      <hr />
      <div className="row">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <ListAdmin
                admin={admin}
                handleRemove={handleRemove}
                key={admin._id}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          current={page}
          total={(adminCount / 5) * 10}
          onChange={(value) => setPage(value)}
        />
      </div>
    </div>
  );
};

export default AdminList;
