/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { Avatar, Badge } from "antd";
// import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import Compress from "compress.js";

const FileUpload = ({ values, setValues, loading, setLoading }) => {
  const authtoken = localStorage.getItem("token");

  // const resizeFile = (file) => {
  //   // console.log("FILE", file);
  //   new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       512,
  //       512,
  //       "JPEG",
  //       70,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //         console.log("URI", uri);
  //       },
  //       "base64"
  //     );
  //   });
  //   // return resolve;
  // };
  // const dataURIToBlob = (dataURI) => {
  //   console.log("DATA URI", dataURI);
  //   const splitDataURI = dataURI.split(",");
  //   const byteString =
  //     splitDataURI[0].indexOf("base64") >= 0
  //       ? atob(splitDataURI[1])
  //       : decodeURI(splitDataURI[1]);
  //   const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  //   const ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i);
  //   return new Blob([ia], { type: mimeString });
  // };
  const onChange = async (e) => {
    setLoading(true);
    const file = e.target.files;
    // console.log("FILE", file[0]);
    const allImages = [];
    if (file) {
      // for (let i = 0; i == file.length; i++) {
      // const image = await resizeFile(file[i]);
      console.log("IMAGE", file);
      // const newFile = dataURIToBlob(image);
      allImages.push(file[0]);
      setValues({ ...values, images: allImages });
      setLoading(false);
      // }
    }
  };
  const handleImageRemove = (public_id) => {
    setLoading(true);
    // console.log("remove image", public_id);
    axios
      .post(
        `${process.env.REACT_APP_API}/removeimages`,
        { public_id },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        const { images } = values;
        let filteredImages = images.filter((item) => {
          return item.public_id !== public_id;
        });
        setValues({ ...values, images: filteredImages });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div>
      <div className="row mb-3">
        <label className="btn btn-primary btn-raised">
          Choose Image File
          <input
            type="file"
            name="image"
            multiple
            hidden
            accept="images/*"
            onChange={onChange}
          />
        </label>
      </div>
      {loading ? <LoadingOutlined className="text-danger h1" /> : ""}
      <div className="row mb-5">
        {JSON.stringify(values.images)}
        {/* {values.images &&
          values.images.map((image, index) => (
            <Badge
              count="X"
              key={index}
              onClick={() => handleImageRemove(image.alt)}
              style={{ cursor: "pointer" }}
            >
              <Avatar
                src={image.data}
                size={150}
                shape="square"
                className="ml-3"
              />
            </Badge>
          ))} */}
      </div>
    </div>
  );
};

export default FileUpload;
