/* eslint-disable no-undef */
import axios from "axios";

export const createAdmin = async (admin, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/register`,
    admin,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getAdminsByCount = async (count) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/list/${count}`);
};
export const getAllAdmins = async (sort, order, page, authtoken) => {
  // console.log(authtoken, sort, order, page);
  const data = { sort, order, page };
  return await axios.post(`${process.env.REACT_APP_API}/admin/getAll`, data, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const fetchAdminsByFilter = async (arg) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/search/filters`,
    arg
  );
};
export const getAdmin = async (id, authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/detail/${id}`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const getRelated = async (productId) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/admin/product/related/${productId}`
  );
};
export const removeAdmin = async (id, authtoken) => {
  return await axios.delete(
    `${process.env.REACT_APP_API}/admin/remove/${id}`,

    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const updateAdmin = async (id, data, authtoken) => {
  return await axios.patch(
    `${process.env.REACT_APP_API}/admin/update/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
};
export const getAdmins = async (sort, order, page) => {
  return await axios.post(`${process.env.REACT_APP_API}/admin/products`, {
    sort,
    order,
    page,
  });
};
export const getAdminTotal = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admin/adminCount`, {
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  });
};
export const productStar = async (productId, star, authtoken) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/admin/product/star/${productId}`,
    { star },
    {
      headers: {
        authtoken,
      },
    }
  );
};
