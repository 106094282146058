import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoBox,
} from "@react-google-maps/api";

// import AdminNav from "../components/nav/AdminNav";
import { listTechnician } from "../functions/dashboard";

const containerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: -6.206664253839927,
  lng: 106.83845080409377,
};

const AdminDashboard = () => {
  const authtoken = localStorage.getItem("token");
  const [technician, setTechnician] = useState([]);
  // const [clickMarker, setClickMarker] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-api",
    region: "ID",
    googleMapsApiKey: "AIzaSyBSsPrc2tC9DfleTfMnvks5CrEtd2oOSTY",
  });

  // const [map, setMap] = useState(null);
  const loadTechnician = () => {
    listTechnician(authtoken).then((res) => setTechnician(res.data));
  };
  // const clickMarker = () => {
  //   setClickMarker()
  // }
  // const onLoad = useCallback(function callback(maps) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   maps.fitBounds(bounds);
  //   setMap(maps);
  // }, []);

  // const onUnmount = useCallback(function callback() {
  //   setMap(null);
  //   console.log(map);
  // }, []);
  useEffect(() => {
    if (technician.length === 0) loadTechnician();
  }, []);
  return (
    <div className="mt-3">
      <h4>Admin Dashboard Page</h4>
      {/* <p>{JSON.stringify(technician)}</p> */}
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          defaultCenter={center}
          center={center}
          zoom={10}
          // onLoad={onLoad}
          // onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          {technician.length > 0 &&
            technician.map((tek, index) => {
              const latit = tek.position.latitude;
              const longit = tek.position.longitude;
              console.log("LAT", latit, "LONG", longit);
              const options = {
                closeBoxURL: "",
                enableEventPropagation: true,
              };
              return (
                <div key={index}>
                  <Marker
                    position={{ lat: latit, lng: longit }}
                    key={tek.mainPhone}
                    visible={true}
                    icon={"https://kasaba.id/frontend/assets/images/driver.png"}
                  >
                    <InfoBox
                      options={options}
                      position={{ lat: latit, lng: longit }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          opacity: 0.75,
                          padding: 5,
                        }}
                      >
                        <div style={{ fontSize: 12, fontColor: `#08233B` }}>
                          {tek.fullName}
                        </div>
                      </div>
                    </InfoBox>
                  </Marker>
                </div>
              );
            })}
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminDashboard;
